import {
  GoogleSpreadsheetSyncTypesEnum,
  SYNC_DATASOURCE_I18N,
  DEPLOY_GAS,
  SYNC_DATASOURCE_SMO,
} from '@api/client'
import {
  HStack,
  Button,
  Text,
  Box,
  Tooltip,
  Heading,
  Icon,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react'
import { AsideProps, Aside, AsideHeader, AsideBody } from '@saas-ui-pro/react'
import {
  OverflowMenu,
  PersonaAvatar,
  Timeline,
  TimelineContent,
  TimelineIcon,
  TimelineItem,
  TimelineSeparator,
  TimelineTrack,
  useSnackbar,
} from '@saas-ui/react'
import { GoogleSpreadsheetReactFlowInstance } from './flow'
import { Spreadsheet } from './types'
import type { Node } from 'reactflow'
import { NodeSidebar, googleSheetGreen, postgresBlue } from './nodes'
import { FiArrowRight, FiMenu, FiRefreshCw } from 'react-icons/fi'
import { DateTime, RelativeTime } from '@app/i18n'
import React from 'react'
import { SiGooglesheets, SiPostgresql } from 'react-icons/si'
import {
  Datasource,
  useCurrentUser,
} from '@app/features/core/hooks/use-current-user'
import { useMutation } from '@apollo/client'

interface GoogleSheetSidebarProps extends AsideProps {
  selectedNode: Node | null
  selectedDatasource: Datasource | null
  spreadsheet: Spreadsheet
  rfInstance: GoogleSpreadsheetReactFlowInstance
}

const ActivityDate: React.FC<{ date: Date }> = (props) => {
  return (
    <Tooltip label={<DateTime date={props.date} />}>
      <Text as="span" color="muted">
        <RelativeTime date={props.date} />
      </Text>
    </Tooltip>
  )
}

type ActivityItem = {
  user?: {
    name: string
    id?: string
    pictureUrl?: string | null
  }
  color?: string
  action: string | React.ReactNode
  date?: Date
}

const syncTypeBoxSize = 5
const DatabaseIcon = () => (
  <Icon
    as={SiPostgresql}
    boxSize={syncTypeBoxSize}
    color={postgresBlue}
    mr={1}
  />
)
const RightArrowIcon = () => (
  <Icon as={FiArrowRight} boxSize={syncTypeBoxSize - 1} />
)
const GoogleSheetIcon = () => (
  <Icon
    as={SiGooglesheets}
    boxSize={syncTypeBoxSize}
    color={googleSheetGreen}
  />
)

const SyncType = ({
  syncType,
}: {
  syncType: GoogleSpreadsheetSyncTypesEnum
}) => {
  return syncType === 'DB_TO_SHEET' ? (
    <>
      <DatabaseIcon />
      <RightArrowIcon />
      <GoogleSheetIcon />
    </>
  ) : (
    <>
      <GoogleSheetIcon />
      <RightArrowIcon />
      <DatabaseIcon />
    </>
  )
}

export const GoogleSheetActivity: React.FC<GoogleSheetSidebarProps> = (
  props,
) => {
  const { selectedDatasource, spreadsheet, rfInstance, ...rest } = props

  const creationActivity: ActivityItem = React.useMemo(
    () => ({
      // user: { name: 'Someone' },
      action: 'This sheet was created',
      date: spreadsheet?.createdAt
        ? new Date(spreadsheet.createdAt)
        : undefined,
      color: 'green.300',
    }),
    [spreadsheet?.createdAt],
  )

  const syncActivities = React.useMemo<ActivityItem[]>(() => {
    return (
      spreadsheet?.syncSummary.map((ss) => {
        return {
          user: ss.user ?? undefined,
          action: (
            <HStack mt={2} spacing={'1'} mb={1}>
              <SyncType syncType={ss.syncType} />
              <Text>sync {ss.state.toLowerCase()}</Text>
            </HStack>
          ),
          date: new Date(ss.createdAt),
          color: ss.state === 'FAILED' ? 'red.500' : 'green.300',
        }
      }) || []
    )
    // NOTE: no need to sort, GQL query already does so
    // .sort((a, b) => b.date.getTime() - a.date.getTime())
  }, [spreadsheet?.syncSummary])

  const activities: ActivityItem[] = React.useMemo<ActivityItem[]>(() => {
    return [...syncActivities, creationActivity]
  }, [creationActivity, syncActivities])

  if (!spreadsheet) return null
  return (
    <Box>
      <Heading size="xs" mb={2}>
        Latest activity
      </Heading>
      <Timeline>
        {activities.map(({ user, action, date, color }, i) => (
          <TimelineItem key={i} mb={2}>
            <TimelineSeparator>
              <TimelineIcon color={color} zIndex={'base'}>
                {user?.id && (
                  <PersonaAvatar
                    name={user.name}
                    size={'xs'}
                    colorScheme={'green.300'}
                    src={user.pictureUrl || undefined}
                  />
                )}
              </TimelineIcon>
              {i < activities.length - 1 && <TimelineTrack />}
            </TimelineSeparator>
            <TimelineContent>
              {typeof action === 'string' ? (
                <Box>
                  {user?.name && <strong>{user.name}</strong>}{' '}
                  <span>{action}</span>
                </Box>
              ) : (
                action
              )}{' '}
              {date && <ActivityDate date={date} />}
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Box>
  )
}

export const GoogleSheetSidebar: React.FC<GoogleSheetSidebarProps> = (
  props,
) => {
  const { selectedNode, selectedDatasource, spreadsheet, rfInstance, ...rest } =
    props

  const { project, projectId, projects, setProjectId } = useCurrentUser()
  const snackbar = useSnackbar()

  const [resyncI18n, { loading: isLoadingI18nResync }] = useMutation(
    SYNC_DATASOURCE_I18N,
    {
      onCompleted: () => snackbar.success('Synced latest i18n data'),
      onError: () => snackbar.error('Failed to sync translations'),
    },
  )
  const [resyncSmo, { loading: isLoadingSmoResync }] = useMutation(
    SYNC_DATASOURCE_SMO,
    {
      onCompleted: () => {
        snackbar.success('Synced latest schema metadata overview')
      },
      onError: () => {
        snackbar.error('Failed to sync schema metadata overview')
      },
    },
  )

  const [deployGAS, { loading: isDeployingGAS }] = useMutation(DEPLOY_GAS, {
    onCompleted: () => {
      snackbar.success('Deployed latest GAS extension')
    },
    onError: async (error: any, variables) => {
      if (error.response?.errors[0]?.extensions?.type === 'reauth') {
        snackbar.error({
          title:
            'You will need to re-authenticate with Google to deploy the App Script extension.',
          description: 'Redirecting in 3 seconds...',
          duration: 3000,
        })
        await new Promise((resolve) => setTimeout(resolve, 3000))
        window.location.href = `/api/google/auth?project_id=${projectId}&redirect=${window.location.pathname}`
      } else {
        snackbar.error('Could not re-deploy Google Apps Script extension')
      }
    },
  })

  return (
    <Aside
      key={selectedNode?.id}
      defaultWidth={400}
      minWidth="300px"
      maxWidth="400px"
      borderLeftWidth="1px"
      isResizable
      size="md"
      {...rest}
    >
      {selectedNode ? (
        <NodeSidebar node={selectedNode} />
      ) : (
        <>
          <AsideHeader>
            <Text mr={2}>Google Sheet</Text>
            {selectedDatasource?.id && (
              <OverflowMenu size="md" variant={'secondary'} icon={<FiMenu />}>
                <MenuItem
                  isDisabled={isLoadingI18nResync}
                  onClick={() =>
                    resyncI18n({
                      variables: { datasourceId: selectedDatasource?.id },
                    })
                  }
                >
                  Fetch translations from DB
                </MenuItem>
                <MenuDivider />
                <MenuItem
                  isDisabled={isLoadingSmoResync}
                  onClick={() =>
                    resyncSmo({
                      variables: {
                        datasourceId: selectedDatasource?.id.toString(),
                        refresh: true,
                      },
                    })
                  }
                >
                  Refresh schema in DB
                </MenuItem>
                <MenuItem
                  isDisabled={isLoadingSmoResync}
                  onClick={() =>
                    resyncSmo({
                      variables: {
                        datasourceId: selectedDatasource?.id?.toString(),
                        refresh: false,
                      },
                    })
                  }
                >
                  Fetch schema from DB
                </MenuItem>
                <MenuDivider />
                <MenuItem
                  isDisabled={isDeployingGAS}
                  onClick={() =>
                    deployGAS({
                      variables: {
                        googleSpreadsheetId:
                          spreadsheet?.id?.toString() as string,
                      },
                    })
                  }
                >
                  Deploy App Script extension
                </MenuItem>
              </OverflowMenu>
            )}
          </AsideHeader>
          <AsideBody>
            <GoogleSheetActivity {...props} />
          </AsideBody>
        </>
      )}
    </Aside>
  )
}
