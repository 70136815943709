import * as React from 'react'

import {
  GET_LATEST_SMOS_FOR_DS,
  PostgresEnvironmentSyncPreview,
  PreviewPostgresEnvironmentSyncMutationVariables,
} from '@api/client'
import { Select, SelectButton, SelectList, useSnackbar } from '@saas-ui/react'

import { Datasource, Project } from '@app/features/core/hooks/use-current-user'
import {
  AbsoluteCenter,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Divider,
  Grid,
  HStack,
  Heading,
  Textarea,
  Tooltip,
  VStack,
  useTheme,
} from '@chakra-ui/react'
import {
  ActiveFiltersList,
  DataGrid,
  DataGridPagination,
  FilterItem,
  FiltersAddButton,
  FiltersProvider,
  ActiveFilterValueInput,
  FilterRenderFn,
  Toolbar,
  ToolbarButton,
} from '@saas-ui-pro/react'
import {
  FiArrowRightCircle,
  FiColumns,
  FiGlobe,
  FiRefreshCw,
  FiTable,
  FiUserPlus,
} from 'react-icons/fi'
import {
  EnvironmentDatasourceSelect,
  EnvironmentSelect,
  MinimalEnvironment,
} from '@app/features/tenants/components/datasources/environment-select'
import { PgEnvSyncSteps } from './helper'
import { useQuery } from '@apollo/client'
import _ from 'lodash'

type PostgresEnvSyncCommonProps = {
  project: Project | null
  preview: Omit<
    PostgresEnvironmentSyncPreview,
    'flow' | 'd2Text' | 'd2Svg'
  > | null
  selectedSourceDatasource: Datasource | null
  selectedTargetDatasource: Datasource | null
  selectedMDE: string | undefined
  isLoadingPreview: boolean
  selectedTrackedTenant: string | undefined
  setCurrentStep: (step: PgEnvSyncSteps) => void
}

export type PostgresEnvSyncConfigureProps = PostgresEnvSyncCommonProps & {
  environments: MinimalEnvironment[]

  selectedSourceDatasource: Datasource | null
  setSelectedSourceDatasource: (ds: Datasource | null) => void

  selectedTargetDatasource: Datasource | null
  setSelectedTargetDatasource: (ds: Datasource | null) => void

  sourceWhere: string
  setSourceWhere: (where: string) => void

  selectedMDE: string | undefined
  selectedSourceEnvironment: MinimalEnvironment | null
  setSelectedSourceEnvironment: (env: MinimalEnvironment | null) => void
  selectedTargetEnvironment: MinimalEnvironment | null
  setSelectedTargetEnvironment: (env: MinimalEnvironment | null) => void
  selectedTrackedTenant: string | undefined
  setSelectedTrackedTenant: (tenant: string) => void
  isLoadingPreview: boolean
}

const singleQuote = (s: string) => `'${s}'`
const wrap = (s: string, left: string, right: string) => `${left}${s}${right}`

const filterItemToSourceWhereSql = (filter: FilterItem) => {
  // @ts-expect-error operator is not typed here, but is present
  const operator = filter.operator as string
  const multiple = filter.value instanceof Array
  const sqlValue = multiple
    ? wrap((filter.value as string[]).map(singleQuote).join(', '), '(', ')')
    : singleQuote(filter.value as string)

  let operatorSql
  switch (operator) {
    case 'is':
      operatorSql = multiple ? 'IN' : '='
      break
    case 'isNot':
      operatorSql = multiple ? 'NOT IN' : '!='
      break
    default:
      operatorSql = '='
  }

  switch (filter.id) {
    case 'tenant':
      return `schemamap.tenants.tenant_short_name ${operatorSql} ${sqlValue}`
    case 'table':
      return `information_schema.tables.table_name ${operatorSql} ${sqlValue}`
    case 'table-column-pred':
    case 'global-column-pred':
      return filter.value

    default:
      return ''
  }
}

export const PostgresEnvSyncConfigure = ({
  preview,
  environments,
  project,

  selectedSourceDatasource,
  setSelectedSourceDatasource,

  selectedTargetDatasource,
  setSelectedTargetDatasource,
  selectedSourceEnvironment,
  setSelectedSourceEnvironment,
  sourceWhere,
  setSourceWhere,
  selectedTargetEnvironment,
  setSelectedTargetEnvironment,
  isLoadingPreview,
  setCurrentStep,
}: PostgresEnvSyncConfigureProps) => {
  const theme = useTheme()
  const snackbar = useSnackbar()

  const { data: latestSmos } = useQuery(GET_LATEST_SMOS_FOR_DS, {
    variables: {
      datasourceId: selectedSourceDatasource?.id as string,
      environmentId: selectedSourceEnvironment?.id as string,
    },
    skip: !selectedSourceDatasource?.id || !selectedSourceEnvironment?.id,
  })

  const dsSMOs = React.useMemo(
    () => latestSmos?.datasourceSchemaMetadataOverview.filter(Boolean) ?? [],
    [latestSmos?.datasourceSchemaMetadataOverview],
  )

  const trackedTenantOptions: FilterItem[] = React.useMemo(
    () =>
      latestSmos?.trackedTenantEnvironments.map((tte) => {
        const tt = tte.trackedTenant
        return {
          id: tt.shortName,
          value: tt.shortName,
          label: tt.name,
        }
      }) ?? [],
    [latestSmos?.trackedTenantEnvironments],
  )

  const filters: FilterItem[] = React.useMemo(() => {
    return [
      trackedTenantOptions.length > 0
        ? {
            id: 'tenant',
            label: 'Tenant',
            type: 'enum',
            multiple: true,
            icon: <FiUserPlus />,
            items: trackedTenantOptions,
          }
        : null,
      {
        id: 'table',
        label: 'Table',
        type: 'enum',
        multiple: true,
        icon: <FiTable />,
        items: Array.from(
          new Set(dsSMOs.map((dssmo) => dssmo.tableName || '')),
        ).map(
          (tablename): FilterItem => ({
            id: tablename || '',
            label: tablename,
            value: tablename,
          }),
        ),
      },
      {
        id: 'global-column-pred',
        label: 'Global Column predicate',
        type: 'enum',
        icon: <FiGlobe />,
        operators: ['is'],
        items: _.chain(dsSMOs)
          .map('columnName')
          .sort()
          .sortedUniq()
          .map(
            (columnname): FilterItem => ({
              id: columnname || '',
              label: columnname,
              value: `${columnname} = `,
            }),
          )
          .value(),
      },
      {
        id: 'table-column-pred',
        label: 'Table Column predicate',
        type: 'enum',
        operators: ['is'],
        icon: <FiColumns />,
        items: _.sortBy(
          dsSMOs.map((dssmo) => {
            const label = `${dssmo.tableName}.${dssmo.columnName}`
            return {
              id: label,
              label,
              value: `${label} = `,
            }
          }),
          'label',
        ),
      },
    ].filter(Boolean) as FilterItem[]
  }, [trackedTenantOptions, dsSMOs])

  const onFilter = React.useCallback(
    (filters: FilterItem[]) => {
      setSourceWhere(
        filters.map((f) => filterItemToSourceWhereSql(f)).join(' AND \n'),
      )
    },
    [setSourceWhere],
  )

  const renderValue: FilterRenderFn = React.useCallback((context) => {
    if (
      context.id === 'global-column-pred' ||
      context.id === 'table-column-pred'
    ) {
      return <ActiveFilterValueInput />
    }
    return context.value?.toLocaleString()
  }, [])

  return (
    <FiltersProvider filters={filters} onChange={onFilter}>
      <Grid templateColumns={'1fr 1fr'} gap={0} height="100%">
        <VStack pt="5" overflow={'auto'} borderRight="1px solid #444">
          <Heading size={'md'}>Source</Heading>

          <HStack justifyContent={'center'} flexBasis={'0px'} flexWrap={'wrap'}>
            <label>
              <Box>Environment:</Box>

              <EnvironmentSelect
                name="sourceEnvironment"
                environments={environments}
                selectedEnvironment={selectedSourceEnvironment}
                onSelectedEnvironmentChange={(newEnv) => {
                  setSelectedSourceEnvironment(newEnv)
                  setSelectedSourceDatasource(
                    newEnv?.datasources.length == 1
                      ? newEnv.datasources[0]
                      : null,
                  )
                }}
              />
            </label>
            <label>
              <Box>Datasource:</Box>

              <EnvironmentDatasourceSelect
                name={'sourceDatasource'}
                isDisabled={!selectedSourceEnvironment}
                datasources={selectedSourceEnvironment?.datasources || []}
                selectedDatasource={selectedSourceDatasource}
                onSelectedDatasourceChange={setSelectedSourceDatasource}
              />
            </label>

            {selectedSourceDatasource?.id ? (
              <Box mt="5">
                <FiltersAddButton
                  buttonProps={{ variant: 'outline', size: 'lg' }}
                  label="Subset"
                  placeholder="Subset by..."
                />
              </Box>
            ) : null}
          </HStack>

          {sourceWhere ? (
            <Accordion w="full" allowToggle>
              <AccordionItem>
                <HStack w="full">
                  <ActiveFiltersList
                    flex={'1'}
                    flexGrow={'1'}
                    width={'full'}
                    justifyItems={'center'}
                    borderBottom={'none'}
                    renderValue={renderValue}
                  />
                  <AccordionButton flex={'0'} alignSelf={'flex-end'}>
                    SQL <AccordionIcon />
                  </AccordionButton>
                </HStack>

                <AccordionPanel>
                  <Textarea
                    w="full"
                    value={sourceWhere}
                    resize={'vertical'}
                    onChange={(e) => setSourceWhere(e.target.value)}
                  />
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          ) : (
            <Divider orientation="horizontal" />
          )}

          {preview ? (
            <DataGrid
              isHoverable
              isSelectable
              isSortable
              columns={
                preview?.sourceMDEs.columns.map((c, index) => ({
                  id: c,
                  accessorFn: (row: string[]) => row[index],
                  header: c,
                })) || []
              }
              data={preview?.sourceMDEs.rows ?? []}
            >
              {(preview?.sourceMDEs.rows?.length ?? 0) > 10 ? (
                <DataGridPagination />
              ) : null}
            </DataGrid>
          ) : null}
        </VStack>
        <VStack pt="5" overflow={'auto'}>
          <Heading size={'md'}>Target</Heading>
          <HStack flexWrap={'wrap'} flexBasis={'0px'} flexGrow={'0'}>
            <label>
              <Box>Environment:</Box>

              <EnvironmentSelect
                name={'targetEnvironment'}
                environments={environments}
                selectedEnvironment={selectedTargetEnvironment}
                onSelectedEnvironmentChange={(newEnv) => {
                  setSelectedTargetEnvironment(newEnv)
                  setSelectedTargetDatasource(
                    newEnv?.datasources.length == 1
                      ? newEnv.datasources[0]
                      : null,
                  )
                }}
              />
            </label>
            <label>
              <Box>Datasource:</Box>
              <EnvironmentDatasourceSelect
                name={'targetDatasource'}
                isDisabled={!selectedTargetEnvironment}
                datasources={selectedTargetEnvironment?.datasources || []}
                selectedDatasource={selectedTargetDatasource}
                onSelectedDatasourceChange={setSelectedTargetDatasource}
              />
            </label>
          </HStack>

          <Divider />
          {preview ? (
            <DataGrid
              isHoverable
              isSortable
              columns={
                preview?.targetMDEs.columns.map((c, index) => ({
                  id: c,
                  accessorFn: (row: string[]) => row[index],
                  header: c,
                })) || []
              }
              data={preview?.targetMDEs.rows ?? []}
            >
              {(preview?.targetMDEs.rows?.length ?? 0) > 10 ? (
                <DataGridPagination />
              ) : null}
            </DataGrid>
          ) : null}
        </VStack>
      </Grid>
    </FiltersProvider>
  )
}

type PreviewEnvSyncType = {
  variables: PreviewPostgresEnvironmentSyncMutationVariables
}

type PostgresEnvSyncToolbarProps = PostgresEnvSyncCommonProps & {
  previewEnvSync: (options: PreviewEnvSyncType) => Promise<any>
  setSelectedMDE: (mde: string) => void
  calculatePreview: () => void
}

export const PostgresEnvSyncToolbar = ({
  preview,
  calculatePreview,
  selectedSourceDatasource,
  selectedTargetDatasource,
  selectedMDE,
  isLoadingPreview,
  project,
  setCurrentStep,
  setSelectedMDE,
}: PostgresEnvSyncToolbarProps) => {
  const mdeOptions = React.useMemo(
    () =>
      project?.trackedMdes.map((mde) => ({
        value: mde.id,
        label: mde.name,
      })),
    [project?.trackedMdes],
  )

  const primaryAction = (
    <ToolbarButton
      label={!preview ? 'Preview environment sync' : 'Review mapping'}
      variant={'primary'}
      isDisabled={
        selectedSourceDatasource?.id == null ||
        selectedTargetDatasource?.id == null
      }
      isLoading={isLoadingPreview}
      onClick={!preview ? calculatePreview : () => setCurrentStep('review')}
    />
  )

  return (
    <Toolbar alignItems={'center'}>
      {(mdeOptions?.length ?? 0) > 0 ? (
        <label style={{ display: 'flex', alignItems: 'center' }}>
          <Box mr={2}>Master Data Entity:</Box>

          <Select
            id="mde"
            name="mde"
            tabIndex={0}
            autoFocus
            value={selectedMDE}
            placeholder={'Select a Master Data Entity'}
            size="sm"
            options={mdeOptions}
            onChange={(value) => {
              setSelectedMDE(value as string)
            }}
          >
            <SelectButton />
            <SelectList />
          </Select>
        </label>
      ) : null}

      {preview ? (
        <ToolbarButton
          label="Reload preview"
          leftIcon={<FiRefreshCw />}
          isLoading={isLoadingPreview}
          onClick={calculatePreview}
        />
      ) : null}

      {primaryAction}
    </Toolbar>
  )
}
