import * as React from 'react'
import { z } from 'zod'
import {
  Badge,
  BadgeProps,
  Box,
  Button,
  MenuItem,
  Text,
} from '@chakra-ui/react'

import {
  EmptyState,
  FormLayout,
  OverflowMenu,
  Select,
  SelectButton,
  SelectList,
  SelectOption,
  createField,
  useHotkeysShortcut,
  useSnackbar,
} from '@saas-ui/react'
import { FiServer } from 'react-icons/fi'

import {
  DataGridCell,
  Toolbar,
  ToolbarButton,
  Command,
  useColumns,
} from '@saas-ui-pro/react'
import { Breadcrumbs, ListPage, useModals } from '@ui/lib'

import {
  Environment,
  useCurrentUser,
} from '@app/features/core/hooks/use-current-user'

import {
  EnvironmentTypesEnum,
  CREATE_ENVIRONMENTS,
  DELETE_ENVIRONMENT,
  GET_CURRENT_USER,
} from '@api/client'
import { useMutation } from '@apollo/client'
import { usePath } from '@app/features/core/hooks/use-path'

const ActionCell: DataGridCell<Environment> = (cell) => {
  const { invalidate } = useCurrentUser()
  const snackbar = useSnackbar()

  const [deleteMutation] = useMutation(DELETE_ENVIRONMENT, {
    onCompleted: (data) => {
      const deletedEnv = data?.deleteEnvironmentsByPk
      snackbar.success({
        description: `Deleted ${deletedEnv?.name}`,
      })
      invalidate()
    },
    onError: () => {
      snackbar.error({
        description: `Failed to delete environment`,
      })
    },
  })

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <OverflowMenu size="xs">
        <MenuItem
          onClick={() => deleteMutation({ variables: { id: cell.row.id } })}
        >
          Delete
        </MenuItem>
      </OverflowMenu>
    </Box>
  )
}

type CssColor = BadgeProps['colorScheme']

const environmentTypeToColor: Record<EnvironmentTypesEnum, CssColor> = {
  LOCAL: 'green',
  DEV: 'green',
  TRAINING: 'green',
  STAGING: 'yellow',
  PERFORMANCE: 'yellow',
  SANDBOX: 'yellow',
  QA: 'yellow',
  TEST: 'orange',
  PREPROD: 'orange',
  UAT: 'orange',
  PROD: 'red',
  DISASTER_RECOVERY: 'red',
} as const

const environmentTypeOrder: Record<EnvironmentTypesEnum, number> = {
  LOCAL: 0,
  DEV: 1,
  TRAINING: 2,
  STAGING: 3,
  PERFORMANCE: 4,
  SANDBOX: 5,
  QA: 6,
  TEST: 7,
  PREPROD: 8,
  UAT: 9,
  PROD: 10,
  DISASTER_RECOVERY: 11,
} as const

const schema = z.object({
  name: z.string().min(2, 'Too short').max(25, 'Too long').describe('Name'),
  description: z
    .string()
    .max(400, 'Too long')
    .describe('Description')
    .optional(),
  type: z.nativeEnum(EnvironmentTypesEnum).describe('Type'),
})

export const EnvironmentTypeSelectField = createField(
  React.forwardRef((props: any, ref) => {
    const { name, value, onChange } = props
    const listOptions = React.useMemo(() => {
      return Object.values(EnvironmentTypesEnum)
        .sort((a, b) => environmentTypeOrder[a] - environmentTypeOrder[b])
        .map((type) => (
          <SelectOption key={type} value={type}>
            <Badge variant="subtle" colorScheme={environmentTypeToColor[type]}>
              {type}
            </Badge>
          </SelectOption>
        ))
    }, [])

    return (
      <Select
        // ref={ref as React.RefObject<HTMLSelectElement> | null}
        name={name}
        autoFocus
        value={value}
        placeholder={'Select an environment type'}
        size="sm"
        renderValue={(value) => {
          return (
            <Badge
              variant="subtle"
              colorScheme={
                environmentTypeToColor[value as EnvironmentTypesEnum]
              }
            >
              {value}
            </Badge>
          )
        }}
        onChange={(value) => {
          onChange({ target: { value } })
        }}
      >
        <SelectButton />
        <SelectList>{listOptions}</SelectList>
      </Select>
    )
  }),
  { isControlled: true },
)

export const EnvironmentsListPage: React.FC<{ projectId: string }> = ({
  projectId,
}) => {
  const { user, project, isLoading, tenantId } = useCurrentUser()
  const snackbar = useSnackbar()
  const modals = useModals()

  const [mutation] = useMutation(CREATE_ENVIRONMENTS, {
    onCompleted: (data) => {
      snackbar.success({
        description: `Environment(s) created`,
      })
    },
  })

  const addBasicEnvironments = async () => {
    try {
      return await mutation({
        variables: {
          environments: [
            {
              tenantId,
              name: `${user?.fullName} Local`,
              projectId,
              type: EnvironmentTypesEnum.Local,
            },
            {
              tenantId,
              name: 'Staging',
              projectId,
              type: EnvironmentTypesEnum.Staging,
            },
            {
              tenantId,
              name: 'Prod',
              projectId,
              type: EnvironmentTypesEnum.Prod,
            },
          ],
        },
      })
    } catch (e) {
      snackbar.error('Could not create environments')
    }
  }

  const emptyState = (
    <EmptyState
      title="No environments added yet"
      description="Start adding environments to connect to your DBs."
      colorScheme="primary"
      icon={FiServer}
      actions={
        <>
          <Button
            colorScheme="primary"
            variant="solid"
            onClick={addBasicEnvironments}
          >
            Add basic environments
          </Button>
        </>
      }
    />
  )
  const basePath = usePath('/')

  const columns = useColumns<Environment>(
    (helper) => [
      // @ts-ignore
      helper.accessor('name', {
        header: 'Name',
        size: 300,
        meta: {
          href: ({ id }: any) =>
            `${basePath}/projects/${projectId}/environments/${id}`,
        },
      }),
      helper.accessor('description', {
        header: 'Description',
        size: 300,
        cell: (cell) => <Text variant="muted">{cell.getValue()}</Text>,
      }),
      helper.accessor('type', {
        header: 'Type',
        size: 300,
        cell: (cell) => (
          <Badge
            variant="subtle"
            colorScheme={environmentTypeToColor[cell.getValue()]}
          >
            {cell.getValue()}
          </Badge>
        ),
      }),
      helper.display({
        id: 'action',
        header: '',
        cell: ActionCell,
        size: 100,
        enableGlobalFilter: false,
        enableHiding: false,
        enableSorting: false,
      }),
    ],
    [],
  )

  const addEnvironment = () => {
    modals.form({
      title: 'Add environment',
      defaultValues: {
        name: '',
        description: '',
        type: EnvironmentTypesEnum.Local,
      },
      schema,
      children: ({ Field }) => (
        <FormLayout>
          <Field name="name" label="Name" />
          <Field name="description" label="Description" />
          <Field name="type" label="Type" as={EnvironmentTypeSelectField} />
        </FormLayout>
      ),
      fields: {
        submit: {
          children: 'Save',
        },
      },
      onSubmit: async (environment) => {
        try {
          await mutation({
            variables: {
              environments: [{ ...environment, projectId, tenantId }],
            },
            refetchQueries: [GET_CURRENT_USER],
          }),
            modals.closeAll()
        } catch (e) {
          snackbar.error('Could not create environment')
        }
      },
    })
  }

  const breadcrumbs = (
    <Breadcrumbs
      items={[
        { href: usePath('/projects'), title: 'Projects' },
        { title: project?.name },
        { title: 'Environments' },
      ]}
    />
  )

  const addCommand = useHotkeysShortcut('environments.add', addEnvironment)

  const primaryAction = (
    <ToolbarButton
      label="Add environment"
      variant="solid"
      size="md"
      colorScheme="primary"
      onClick={addEnvironment}
      tooltipProps={{
        label: (
          <>
            Add an environment <Command>{addCommand}</Command>
          </>
        ),
      }}
    />
  )

  const toolbar = <Toolbar size="sm">{primaryAction}</Toolbar>

  return (
    <ListPage<Environment>
      title={breadcrumbs}
      toolbar={toolbar}
      emptyState={emptyState}
      columns={columns}
      visibleColumns={['name', 'description', 'type', 'action']}
      data={project?.environments ?? []}
      isLoading={isLoading}
    />
  )
}
