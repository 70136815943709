import * as React from 'react'

import { Steps, StepsItem } from '@saas-ui/react'
import { OnboardingPage, CreateProjectStep } from '../../components/onboarding'
import { useRouter } from '@app/nextjs'

export const CreateProjectPage: React.FC = () => {
  const router = useRouter()
  return (
    <OnboardingPage>
      <Steps
        step={1}
        maxW={'container.xl'}
        variant={['dots', null, null, 'horizontal']}
      >
        <StepsItem
          title="Create organization"
          id="create-organization"
        ></StepsItem>
        <StepsItem title="Create project" id="create-project">
          <CreateProjectStep />
        </StepsItem>
      </Steps>
    </OnboardingPage>
  )
}
