import { SyncPostgresEnvMutation } from '@api/client'

import {
  HStack,
  Heading,
  VStack,
  Text,
  CardHeader,
  Card,
  CardBody,
  Stack,
  Box,
  Center,
  Code,
  Flex,
  Spacer,
  SimpleGrid,
} from '@chakra-ui/react'
import { Property, PropertyLabel, PropertyList } from '@saas-ui/react'
import { CodeCopy } from '@app/features/tenants/components/onboarding/wizard/connect-env'

type PostgresEnvSyncResultProps = {
  syncResult: SyncPostgresEnvMutation
}

type DataMigrationSummary = {
  functions: Record<string, { description?: string }>
  views: Record<string, { description?: string }>
  tables: Record<
    string,
    {
      bytesWritten: number
      rowsCopied: number
      rowsWritten: number
      error?: { severity: string; code: number; message: string }
    }
  >
}

export const PostgresEnvSyncResult = ({
  syncResult,
}: PostgresEnvSyncResultProps) => {
  const { syncPostgresEnvironment } = syncResult
  const {
    dataMigration,
    syncedTotalMegabytes,
    syncDurationSeconds,
    syncedTotalRecords,
  } = syncPostgresEnvironment ?? {}

  if (!dataMigration || !dataMigration.summary) return

  const summary = JSON.parse(dataMigration.summary) as DataMigrationSummary

  return (
    <VStack width="full">
      <Card p={8} mt={20}>
        <CardBody maxW={600}>
          <Heading mb={8} size={'lg'}>
            Sync Complete 🎉
          </Heading>
          <Text fontSize={'xl'}>
            Data migration #{dataMigration.id} loaded into target DB.
          </Text>
          <Text fontSize={'xl'}>
            To run the import, run this SQL with your application Postgres user:{' '}
            <br />
            <CodeCopy
              textToCopy={`select * from schemamap.dm_${dataMigration.id}_import();`}
              onCopyText={'Copied import SQL to clipboard'}
            >
              select * from schemamap.dm_{dataMigration.id}_import();
            </CodeCopy>
          </Text>
        </CardBody>
      </Card>

      <SimpleGrid
        mx={6}
        my={6}
        columns={[1, null, null, null, null, 3]}
        spacing={6}
      >
        <Card>
          <CardHeader fontSize={'lg'} pb={0}>
            Statistics
          </CardHeader>
          <CardBody>
            <PropertyList width={'full'}>
              <Property label={'ID'} value={dataMigration.id} />
              <Property label={'State'} value={dataMigration.state} />
              <Property
                label="Duration"
                value={`${syncDurationSeconds} seconds`}
              />
              <Property label="Total Records" value={syncedTotalRecords} />
              <Property
                label="Data Transferred"
                value={`${syncedTotalMegabytes} MB`}
              />
            </PropertyList>
          </CardBody>
        </Card>
        <Card>
          <CardHeader fontSize={'lg'} pb={0}>
            Functions ({Object.entries(summary.functions).length})
          </CardHeader>
          <CardBody pt={6}>
            {Object.entries(summary.functions)
              .sort(([aFuncNAme], [bFuncName]) => {
                return bFuncName.localeCompare(aFuncNAme)
              })
              .map(([funcName, { description }]) => (
                <Box key={funcName} mb={4}>
                  <Heading size={'sm'}>{funcName}()</Heading>
                  {description && <Text>{description}</Text>}
                </Box>
              ))}
          </CardBody>
          {Object.keys(summary.views).length > 0 && (
            <>
              <CardHeader fontSize={'lg'} py={0}>
                Views ({Object.keys(summary.views).length})
              </CardHeader>
              <CardBody pt={6}>
                {Object.entries(summary.views)
                  .sort(([aViewName], [bViewName]) => {
                    return bViewName.localeCompare(aViewName)
                  })
                  .map(([viewName, { description }]) => (
                    <Box key={viewName} mb={4}>
                      <Heading size={'sm'}>{viewName}</Heading>
                      {description && <Text>{description}</Text>}
                    </Box>
                  ))}
              </CardBody>
            </>
          )}
        </Card>
        <Card>
          <CardHeader fontSize={'lg'} pb={0}>
            Tables ({Object.keys(summary.tables).length})
          </CardHeader>
          <CardBody>
            {Object.entries(summary.tables).map(([tableName, tableSummary]) => (
              <Box key={tableName} mt={2}>
                <Heading size={'sm'}>{tableName}</Heading>
                {tableSummary.error ? (
                  <Property
                    label={'Error'}
                    value={
                      <Code colorScheme="red" mt={2} p={4}>
                        {tableSummary.error.message}
                      </Code>
                    }
                  />
                ) : (
                  <>
                    <Property
                      label={'Rows Copied'}
                      value={tableSummary.rowsCopied}
                    />
                    <Property
                      label={'Rows Written'}
                      value={tableSummary.rowsWritten}
                    />
                    <Property
                      label={'Bytes Written'}
                      value={tableSummary.bytesWritten}
                    />
                  </>
                )}
              </Box>
            ))}
          </CardBody>
        </Card>
      </SimpleGrid>
    </VStack>
  )
}
