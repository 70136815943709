import { useState } from 'react'
import { Button, Select, Spinner, HStack } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import { GET_PROJECT_GOOGLE_DRIVE_FOLDERS } from '@api/client'

interface GoogleDriveFolderSelectProps {
  projectId: string
  onFolderSelect: (folderId: string | undefined) => void
}

export default function GoogleDriveFolderSelect({
  projectId,
  onFolderSelect,
}: GoogleDriveFolderSelectProps) {
  const [selectedFolderId, setSelectedFolderId] = useState<string | undefined>(
    undefined,
  )

  const {
    data,
    loading: isLoading,
    error,
    refetch,
  } = useQuery(GET_PROJECT_GOOGLE_DRIVE_FOLDERS, {
    variables: { projectId },
  })

  if (isLoading) return <Spinner />
  if (error) return <p>Error :(</p>

  const options = data?.listProjectGoogleDriveFolders?.map((folder) => ({
    value: folder.id,
    label: folder.name,
  }))

  return (
    <HStack>
      <Select
        placeholder="Select a Google Drive folder"
        value={selectedFolderId}
        onChange={(e) => {
          setSelectedFolderId(e.target.value)
          onFolderSelect(e.target.value)
        }}
      >
        {options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
      <Button variant="primary" isLoading={isLoading} onClick={() => refetch()}>
        Refetch
      </Button>
    </HStack>
  )
}
