import {
  SystemStyleObject,
  useColorMode,
  useMultiStyleConfig,
  useTheme,
} from '@chakra-ui/react'
import React from 'react'
import {
  JsonView,
  allExpanded,
  darkStyles,
  defaultStyles,
} from 'react-json-view-lite'
import { StyleProps } from 'react-json-view-lite/dist/DataRenderer'
import 'react-json-view-lite/dist/index.css'

export type I18nJsonViewerProps = {
  data: object
}

// https://github.com/AnyRoad/react-json-view-lite/blob/1b884191b181684bd200a3961f14e690ca49b77b/src/index.tsx#L27

export const I18nJsonViewer = ({ data }: I18nJsonViewerProps) => {
  const { colorMode } = useColorMode()

  const styles: StyleProps = React.useMemo(() => {
    return colorMode == 'dark' ? darkStyles : defaultStyles
  }, [colorMode])

  return <JsonView data={data} shouldExpandNode={allExpanded} style={styles} />
}
