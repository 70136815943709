import * as React from 'react'

import { z } from 'zod'

import {
  Box,
  Button,
  MenuItem,
  Menu,
  MenuButton,
  MenuList,
  Portal,
  HStack,
  Text,
  Link,
  Badge,
  ButtonGroup,
  Highlight,
  Stack,
  useClipboard,
  Divider,
} from '@chakra-ui/react'
import { FiCheckCircle, FiSliders } from 'react-icons/fi'
import { Link as ReactLink } from '@saas-ui/react'
import {
  EmptyState,
  OverflowMenu,
  useHotkeysShortcut,
  useSnackbar,
  useLocalStorage,
  ModalsContextValue,
  FormLayout,
  FormStepper,
  NextButton,
  PrevButton,
  Property,
  PropertyList,
} from '@saas-ui/react'
import { StepForm } from '@saas-ui/forms/zod'
import {
  Command,
  Toolbar,
  ToolbarButton,
  DataGridCell,
  MenuProperty,
  ToggleButtonGroup,
  ToggleButton,
  useColumns,
  getDataGridFilter,
  Filter,
} from '@saas-ui-pro/react'

import { ListPage, InlineSearch, useModals, LinkButton } from '@ui/lib'

import {
  CREATE_PROJECT,
  UPDATE_ADMIN_PROJECT_GOOGLE_OAUTH2_CREDS,
  UPDATE_ADMIN_PROJECT_GOOGLE_SERVICE_ACCOUNT_CREDS,
  UPDATE_PROJECT_GOOGLE_DRIVE_FOLDER_ID,
  DELETE_PROJECT,
} from '@api/client'

import { useParams } from '@app/nextjs'

import { usePath } from '@app/features/core/hooks/use-path'
import { FiTool } from 'react-icons/fi'
import GoogleDriveFolderSelect from '@app/features/tenants/components/google/google-drive-folder-select'
import {
  CurrentUserProvider,
  Project,
  useCurrentUser,
} from '@app/features/core/hooks/use-current-user'
import { DateCell } from '@app/features/core/components/date-cell'
import { useMutation } from '@apollo/client'

interface GoogleSetupStepFormProps {
  projectId: string | number
}

const GoogleSetupStepForm = ({ projectId }: GoogleSetupStepFormProps) => {
  const steps = [
    {
      name: 'oauth2_setup',
      schema: z
        .object({
          clientId: z
            .string()
            .length(72)
            .endsWith('apps.googleusercontent.com')
            .describe('Client ID'),
          clientSecret: z.string().length(35).describe('Client Secret'),
        })
        .required(),
    },
    {
      name: 'service_account_setup',
      schema: z.object({
        serviceAccountJson: z
          .string()
          .nonempty()
          .describe('Service Account JSON'),
      }),
    },
    {
      name: 'setup_google_drive_folder',
      schema: z.object({
        googleDriveFolderId: z.string().nonempty().describe('Folder ID'),
      }),
    },
    {
      name: 'finish',
    },
  ]

  const snackbar = useSnackbar()

  const [updateProjectGoogleOauth2Creds] = useMutation(
    UPDATE_ADMIN_PROJECT_GOOGLE_OAUTH2_CREDS,
  )

  const handleUpdateGoogleOauth2Creds = async (params: any) => {
    try {
      await updateProjectGoogleOauth2Creds({
        variables: {
          projectId: projectId.toString(),
          creds: {
            clientId: params.clientId.toString(),
            clientSecret: params.clientSecret.toString(),
          },
        },
      })
      snackbar.success({
        description: 'Google OAuth2 app credentials updated',
      })
    } catch (error) {
      snackbar.error({
        description: 'Failed to update Google OAuth2 app credentials',
      })
      return Promise.reject(error)
    }
  }

  const [updateProjectGoogleDriveFolderId] = useMutation(
    UPDATE_PROJECT_GOOGLE_DRIVE_FOLDER_ID,
  )

  const handleSelectedGoogleDriveFolder = async (
    googleDriveFolderId: string | undefined,
  ) => {
    try {
      await updateProjectGoogleDriveFolderId({
        variables: {
          projectId: projectId.toString(),
          googleDriveFolderId: googleDriveFolderId?.toString(),
        },
      })
      snackbar.success({
        description: 'Project Google Drive folder updated',
      })
    } catch (error) {
      snackbar.error({
        description: 'Failed to update project Google Drive folder',
      })
      return Promise.reject(error)
    }
  }

  const {
    value: serviceAccountEmail,
    setValue: setServiceAccountEmail,
    onCopy: copyServiceAccountEmail,
    hasCopied: hasCopiedServiceAccountEmail,
  } = useClipboard('')

  const [updateProjectGoogleServiceAccountCreds] = useMutation(
    UPDATE_ADMIN_PROJECT_GOOGLE_SERVICE_ACCOUNT_CREDS,
  )

  const handleUpdateGoogleServiceAccountCreds = async (params: any) => {
    try {
      const parsedCreds = JSON.parse(params.serviceAccountJson)
      setServiceAccountEmail(parsedCreds.client_email) // convenience for the next step
      await updateProjectGoogleServiceAccountCreds({
        variables: {
          projectId: projectId.toString(),
          creds: parsedCreds,
        },
      })
      snackbar.success({
        description: 'Google Service Account credentials updated',
      })
    } catch (error) {
      snackbar.error({
        description: 'Failed to update Google Service Account credentials',
      })
      return Promise.reject(error)
    }
  }

  const {
    onCopy: copyAuthorizedRedirectUri,
    hasCopied: hasCopiedAuthorizedRedirectUri,
  } = useClipboard('https://app.schemamap.io/api/google/callback')

  const { tenant } = useCurrentUser()

  return (
    <StepForm
      steps={steps}
      defaultValues={{
        clientId: '',
        clientSecret: '',
        serviceAccountJson: '',
      }}
      onSubmit={async (values) => values}
    >
      {({ Field, FormStep, nextStep }) => {
        return (
          <FormLayout>
            <FormStepper orientation="vertical">
              <FormStep
                name="project"
                title="Setup Google OAuth2 app"
                onSubmit={handleUpdateGoogleOauth2Creds}
              >
                <Stack>
                  <Text>
                    Create a new Google Project specificially for the
                    Schemamap.io integration:
                  </Text>
                  <PropertyList>
                    <Property
                      label="Project name"
                      value={[tenant?.name, 'Schemamap.io'].join(' - ')}
                    />
                  </PropertyList>
                  <Button
                    as={Link}
                    isExternal
                    href="https://console.cloud.google.com/projectcreate"
                  >
                    Create Google Project
                  </Button>
                  <Divider my={5} />
                  <Text>
                    Then create a new OAuth2 client ID for the project within
                    the above project:
                  </Text>
                  <Button
                    as={Link}
                    isExternal
                    href="https://console.cloud.google.com/apis/credentials/oauthclient"
                  >
                    Google Cloud Credentials
                  </Button>
                  <PropertyList>
                    <Property
                      label="Application type"
                      value={'Web application'}
                    />
                    <Property
                      label="Authorized redirect URIs"
                      value={
                        <Box flex="1">
                          https://app.schemamap.io/api/google/callback
                          <Button onClick={copyAuthorizedRedirectUri} ml={4}>
                            {hasCopiedAuthorizedRedirectUri
                              ? 'Copied!'
                              : 'Copy'}
                          </Button>
                        </Box>
                      }
                    />
                  </PropertyList>
                  <FormLayout>
                    <Field name="clientId" isRequired label="Client ID" />
                    <Field
                      name="clientSecret"
                      type="password"
                      isRequired
                      label="Client Secret"
                    />
                    <ButtonGroup>
                      <NextButton />
                      <Button variant="ghost" onClick={nextStep}>
                        Skip
                      </Button>
                    </ButtonGroup>
                  </FormLayout>
                </Stack>
              </FormStep>

              <FormStep
                name="service_account_setup"
                title="Setup Google Service Account"
                onSubmit={handleUpdateGoogleServiceAccountCreds}
              >
                <Stack>
                  <Button
                    as={Link}
                    isExternal
                    href="https://console.cloud.google.com/iam-admin/serviceaccounts/create"
                  >
                    Create Google Service Account
                  </Button>
                  <PropertyList>
                    <Property
                      label="Service account name"
                      value="Schemamap.io"
                    />
                    <Property label="Role" value="Editor" />
                    <Property
                      label="After creation"
                      value="Keys tab, Create new JSON key, download and paste below"
                    />
                  </PropertyList>
                  <FormLayout>
                    <Field
                      name="serviceAccountJson"
                      type="textarea"
                      label="Service Account JSON"
                      placeholder='{
                        "type": "service_account",
                        ...
                      }'
                      autoFocus
                    />
                    <ButtonGroup>
                      <NextButton />
                      <PrevButton variant="ghost" />
                      <Button variant="ghost" onClick={nextStep}>
                        Skip
                      </Button>
                    </ButtonGroup>
                  </FormLayout>
                </Stack>
              </FormStep>
              <FormStep
                name="share_google_drive_folder"
                title="Share Google Drive Folder"
              >
                <FormLayout>
                  <Text>
                    Schemamap.io will need to access a Google Drive folder to
                    store Google Sheets for your project.
                  </Text>
                  <Text>
                    Head on over to one of your{' '}
                    <Link
                      href="https://drive.google.com/drive/shared-drives"
                      isExternal
                      color="primary.700"
                    >
                      Shared Google Drives
                    </Link>{' '}
                    and create a folder for Schemamap.io to use.
                    <br />
                    <Highlight
                      query={['Drive', 'Manager']}
                      styles={{ px: '1', py: '1', bg: 'orange.100' }}
                    >
                      Once done, share the Drive as a Manager role with the
                      Service Account email provided in the previous step
                    </Highlight>
                    {serviceAccountEmail ? (
                      <>
                        <Text as="span">
                          :<br /> {serviceAccountEmail}
                          <Button onClick={copyServiceAccountEmail} ml={4}>
                            {hasCopiedServiceAccountEmail ? 'Copied!' : 'Copy'}
                          </Button>
                        </Text>
                      </>
                    ) : (
                      '.'
                    )}
                  </Text>
                  <GoogleDriveFolderSelect
                    projectId={projectId.toString()}
                    onFolderSelect={(folderId) => {
                      handleSelectedGoogleDriveFolder(folderId || undefined)
                    }}
                  />
                  <ButtonGroup>
                    <NextButton />
                    <PrevButton variant="ghost" />
                    <Button variant="ghost" onClick={nextStep}>
                      Skip
                    </Button>
                  </ButtonGroup>
                </FormLayout>
              </FormStep>
              <FormStep name="finish" title="Finish">
                <FormLayout>
                  <Text>
                    As a last step, we will authorize you with the Oauth2
                    credentials provided.
                  </Text>
                  <Text>
                    This is needed to manage the Schemamap.io Google Sheet
                    extensions.
                  </Text>
                  <ButtonGroup>
                    <LinkButton
                      href={'/api/google/auth?project_id=' + projectId}
                      variant={'solid'}
                      colorScheme="primary"
                    >
                      Authenticate with Google
                    </LinkButton>
                    <PrevButton variant="ghost" />
                  </ButtonGroup>
                </FormLayout>
              </FormStep>
            </FormStepper>
          </FormLayout>
        )
      }}
    </StepForm>
  )
}

const openGoogleSetupDrawer = (
  modals: ModalsContextValue,
  projectId: string | number,
) => {
  const id = modals.drawer({
    title: 'Google Setup',
    size: 'lg',
    body: (
      <CurrentUserProvider>
        <GoogleSetupStepForm projectId={projectId} />
      </CurrentUserProvider>
    ),
  })
}

const ActionCell: DataGridCell<Project> = (cell) => {
  const snackbar = useSnackbar()
  const modals = useModals()
  const { invalidate } = useCurrentUser()

  const [deleteMutation] = useMutation(DELETE_PROJECT, {
    onCompleted: (data) => {
      invalidate()
      snackbar.success({
        description: `Deleted ${data?.deleteProjectsByPk?.name}`,
      })
    },
    onError: () => {
      snackbar.error({
        description: `Failed to delete ${cell.row.getValue('name')}`,
      })
    },
  })

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <OverflowMenu size="xs">
        <MenuItem
          onClick={() => deleteMutation({ variables: { id: cell.row.id } })}
        >
          Delete
        </MenuItem>
        <MenuItem onClick={() => openGoogleSetupDrawer(modals, cell.row.id)}>
          Google Setup
        </MenuItem>
      </OverflowMenu>
    </Box>
  )
}

const schema = z.object({
  name: z.string().min(2, 'Too short').max(25, 'Too long').describe('Name'),
  description: z.string().max(400, 'Too long').describe('Description'),
})

const GoogleSetupButton = ({
  modals,
  cell,
}: {
  modals: ModalsContextValue
  cell: any
}) => {
  return (
    <Button
      onClick={(e) => {
        e.stopPropagation() // There is a row click handler
        openGoogleSetupDrawer(modals, cell.row.id)
      }}
      leftIcon={<FiTool />}
      variant="primary"
      aria-label="Google Setup"
    >
      Setup
    </Button>
  )
}

export function ProjectsListPage() {
  const snackbar = useSnackbar()
  const query = useParams()
  const modals = useModals()

  const basePath = usePath('/')

  const [searchQuery, setSearchQuery] = React.useState('')

  const { projects, setProjectId, isLoading, tenantId, invalidate } =
    useCurrentUser()

  const [mutation] = useMutation(CREATE_PROJECT, {
    onCompleted: (data) => {
      snackbar.success({
        description: `Project created for ${data?.insertProjectsOne?.name}`,
      })
      invalidate()
    },
  })

  const columns = useColumns<Project>(
    (helper) => [
      // @ts-ignore
      helper.accessor('name', {
        header: 'Name',
        size: 200,

        cell: (cell) => (
          <HStack>
            <ReactLink
              href={`${basePath}/projects/${cell.row.id}/environments`}
              onClick={(e) => {
                setProjectId(cell.row.id)
              }}
            >
              {cell.getValue()}
            </ReactLink>
            {/*          {cell.row.getValue('googleDriveFolderId') ? (
              <Badge ml={1} colorScheme="green">
                Active
              </Badge>
            ) : (
              <Badge ml={1} colorScheme="gray">
                Inactive
              </Badge>
            )} */}
          </HStack>
        ),
      }),
      helper.accessor('googleDriveFolderId', {
        header: 'Google',
        size: 200,
        cell: (cell) => {
          const folderId = cell.getValue()
          return folderId ? (
            <Button
              as={Link}
              variant="secondary"
              isExternal
              href={`https://drive.google.com/drive/folders/${folderId}`}
              onClick={(e) => e.stopPropagation()}
            >
              Google Drive folder
            </Button>
          ) : (
            <GoogleSetupButton modals={modals} cell={cell} />
          )
        },
      }),
      helper.accessor('description', {
        header: 'Description',
        size: 300,
        cell: (cell) => <Text color="muted">{cell.getValue()}</Text>,
      }),
      helper.accessor('createdAt', {
        header: 'Created at',
        cell: (cell) => <DateCell date={cell.getValue()} />,
        filterFn: getDataGridFilter('date'),
        enableGlobalFilter: false,
      }),
      helper.accessor('updatedAt', {
        header: 'Updated at',
        cell: (cell) => <DateCell date={cell.getValue()} />,
        filterFn: getDataGridFilter('date'),
        enableGlobalFilter: false,
      }),
      helper.display({
        id: 'action',
        header: '',
        cell: ActionCell,
        size: 100,
        enableGlobalFilter: false,
        enableHiding: false,
        enableSorting: false,
      }),
    ],
    [],
  )

  const addProject = () => {
    modals.form({
      title: 'Add project',
      defaultValues: {
        name: '',
        description: undefined,
      },
      schema,
      fields: {
        submit: {
          children: 'Save',
        },
      },
      onSubmit: async (project) => {
        try {
          await mutation({
            variables: {
              project: {
                ...project,
                tenantId: tenantId?.toString(),
              },
            },
          }),
            modals.closeAll()
        } catch (e) {
          snackbar.error('Could not create project')
        }
      },
    })
  }

  const addCommand = useHotkeysShortcut('projects.add', addProject)

  const [visibleColumns, setVisibleColumns] = useLocalStorage(
    'app.projects.columns.v2',
    [
      'name', //'googleDriveFolderId',
      'description',
    ],
  )

  const displayProperties = (
    <ToggleButtonGroup
      type="checkbox"
      isAttached={false}
      size="xs"
      spacing="0"
      flexWrap="wrap"
      value={visibleColumns}
      onChange={setVisibleColumns}
    >
      {columns.map((col) => {
        if ('accessorKey' in col && col.enableHiding !== false) {
          const id = col.id || col.accessorKey
          return (
            <ToggleButton
              key={id}
              value={id}
              mb="1"
              me="1"
              color="muted"
              _checked={{ color: 'app-text', bg: 'whiteAlpha.200' }}
            >
              {id.charAt(0).toUpperCase() + id?.toString()?.slice(1)}
            </ToggleButton>
          )
        }
        return null
      })}
    </ToggleButtonGroup>
  )

  const primaryAction = (
    <ToolbarButton
      label="Add project"
      variant="solid"
      size="md"
      colorScheme="primary"
      onClick={addProject}
      tooltipProps={{
        label: (
          <>
            Add a project <Command>{addCommand}</Command>
          </>
        ),
      }}
    />
  )

  const toolbarItems = (
    <>
      <Menu>
        <MenuButton
          as={ToolbarButton}
          leftIcon={<FiSliders />}
          label="Display"
          size="sm"
          variant="tertiary"
        />
        <Portal>
          <MenuList maxW="360px">
            <MenuProperty
              label="Display properties"
              value={displayProperties}
              orientation="vertical"
            />
          </MenuList>
        </Portal>
      </Menu>
    </>
  )

  const toolbar = (
    <Toolbar size="sm">
      <InlineSearch
        placeholder="Search by name..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        onReset={() => setSearchQuery('')}
      />
      {primaryAction}
    </Toolbar>
  )

  const tabbar = <Toolbar>{toolbarItems}</Toolbar>

  const defaultFilters: Filter[] = []

  const emptyState = (
    <EmptyState
      title="No projects added yet"
      description="Add a project to get started."
      colorScheme="primary"
      icon={FiCheckCircle}
      actions={
        <>
          <Button colorScheme="primary" variant="solid" onClick={addProject}>
            Add a project
          </Button>
        </>
      }
    />
  )

  return (
    <ListPage<Project>
      title="Projects"
      toolbar={toolbar}
      tabbar={tabbar}
      defaultFilters={defaultFilters}
      searchQuery={searchQuery}
      emptyState={emptyState}
      columns={columns}
      visibleColumns={visibleColumns}
      data={projects ?? []}
      isLoading={isLoading}
    />
  )
}
