import { Box, Code, HStack } from '@chakra-ui/react'
import { useSnackbar } from '@saas-ui/react'

import { AsideProps } from '@saas-ui-pro/react'

import React, { useCallback, useEffect, useMemo } from 'react'
import ReactFlow, {
  Background,
  Controls,
  DefaultEdgeOptions,
  Edge,
  FitViewOptions,
  Node,
  OnSelectionChangeFunc,
  ReactFlowInstance,
} from 'reactflow'
import 'reactflow/dist/style.css'
import { GoogleSpreadsheetReactFlowNodeData, nodeTypes } from './nodes'
import './reactflow-nodes.css'
import { GoogleSheetSidebar } from './sidebar'
import useStore, { RFActions, RFState } from './store'
import { Spreadsheet } from './types'
import { Datasource } from '@app/features/core/hooks/use-current-user'

const fitViewOptions: FitViewOptions = {
  padding: 0.1,
}

const defaultEdgeOptions: DefaultEdgeOptions = {
  animated: true,
}

export type GoogleSpreadsheetReactFlowEdgeData = any
export type GoogleSpreadsheetReactFlowInstance = ReactFlowInstance<
  GoogleSpreadsheetReactFlowNodeData,
  GoogleSpreadsheetReactFlowEdgeData
>

export type GoogleSpreadsheetReactFlowProps = {
  googleSpreadsheetId: string
  selectedDatasource: Datasource | null
  sidebar: AsideProps

  spreadsheet: Spreadsheet
  rfInstance: GoogleSpreadsheetReactFlowInstance | null
  onRfInstanceChange: (
    instance: GoogleSpreadsheetReactFlowInstance | null,
  ) => void
}

// Reactflow formatting params
const padding = 10
const maxBoxWidth = 200

const selector = (state: RFState & RFActions) => ({
  nodes: state.nodes,
  edges: state.edges,
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
  onConnect: state.onConnect,
  setNodes: state.setNodes,
  setEdges: state.setEdges,
  reset: state.reset,
})

export const GoogleSpreadsheetReactFlow = ({
  googleSpreadsheetId,
  selectedDatasource,
  sidebar,
  spreadsheet,
  rfInstance,
  onRfInstanceChange,
}: GoogleSpreadsheetReactFlowProps) => {
  const snackbar = useSnackbar()
  const { nodes, edges, onNodesChange, onEdgesChange, onConnect, reset } =
    useStore(selector)

  const flowKey = useMemo<string | undefined>(
    () =>
      selectedDatasource?.id
        ? `google-spreadsheet.${googleSpreadsheetId}.flow.${selectedDatasource?.id}`
        : undefined,
    [googleSpreadsheetId, selectedDatasource?.id],
  )

  // Reset the flow in case the google spreadsheet id/selectedDatasource changes
  useEffect(() => {
    console.log('Resetting flow for flowKey:', flowKey)
    // reset()
  }, [reset, flowKey])

  const [selectedNodeId, setSelectedNodeId] = React.useState<string | null>(
    null,
  )

  const onSelectionChange: OnSelectionChangeFunc = useCallback((selection) => {
    if (selection.nodes.length > 0) {
      setSelectedNodeId(selection.nodes[0].id)
    } else {
      setSelectedNodeId(null)
    }
  }, [])

  return (
    <HStack
      alignItems="stretch"
      width="100%"
      height="100%"
      overflowX="hidden"
      position="relative"
      spacing="0"
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onSelectionChange={onSelectionChange}
        onConnect={onConnect}
        onInit={(rfInstance) => {
          onRfInstanceChange(rfInstance)
          // Try loading the last synced flow when opening the page
          const lastFlow = spreadsheet?.googleSpreadsheetSyncs?.[0]?.flow
          if (lastFlow) reset(JSON.parse(lastFlow) as RFState)
        }}
        selectNodesOnDrag={true}
        fitView
        snapToGrid
        fitViewOptions={fitViewOptions}
        defaultEdgeOptions={defaultEdgeOptions}
        nodeTypes={nodeTypes}
        nodeDragThreshold={1}
      >
        <Background />
        <Controls />
        {/*<MiniMap pannable zoomable nodeColor={minimapNodeColor} /> */}
        {/*<Panel position="top-right">
          <ButtonGroup>
            <Button onClick={onSave}>Save</Button>
            <Button onClick={onRestore}>Restore</Button>
          </ButtonGroup>
        </Panel>*/}
      </ReactFlow>
      {rfInstance && (
        <GoogleSheetSidebar
          isOpen={sidebar.isOpen}
          rfInstance={rfInstance}
          // TODO: build id-based index in store
          selectedNode={nodes.find((n) => n.id === selectedNodeId) as Node}
          selectedDatasource={selectedDatasource}
          spreadsheet={spreadsheet}
        />
      )}
    </HStack>
  )
}
