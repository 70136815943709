import { useRef } from 'react'
import {
  Field,
  FormLayout,
  UseFormReturn,
  useSnackbar,
  useStepperContext,
} from '@saas-ui/react'
import { useRouter } from '@app/nextjs'
import * as z from 'zod'

import { OnboardingStep } from './onboarding-step'
import {
  CREATE_PROJECT,
  CREATE_ENVIRONMENTS,
  EnvironmentTypesEnum,
  CREATE_TENANT,
  ProjectsInsertInput,
} from '@api/client'

import { useCurrentUser } from '@app/features/core/hooks/use-current-user'
import { useMutation } from '@apollo/client'

const schema = z.object({
  name: z.string().min(2, 'Too short').max(255, 'Too long').describe('Name'),
  description: z.string().max(255, 'Too long').describe('Description'),
})

type FormInput = z.infer<typeof schema>

export const CreateProjectStep = () => {
  const router = useRouter()
  const stepper = useStepperContext()
  const snackbar = useSnackbar()
  const { user, invalidate, tenant } = useCurrentUser()

  const formRef = useRef<UseFormReturn<FormInput>>(null)

  const [upsertTenant] = useMutation(CREATE_TENANT, {})

  const [mutateAsync] = useMutation(CREATE_PROJECT, {})

  const [createEnvs] = useMutation(CREATE_ENVIRONMENTS, {})

  return (
    <>
      <OnboardingStep
        schema={schema}
        formRef={formRef}
        title="Create your project"
        description="What is the name of your product/SaaS?"
        defaultValues={{
          name: '',
          description: '',
        }}
        onSubmit={async (data) => {
          try {
            // Upserting in case the webhooks don't work fast enough
            const tenantResult = await upsertTenant({
              variables: {
                name: tenant?.name || '',
                slug: tenant?.slug || '',
                email: user?.primaryEmailAddress?.emailAddress || '',
                logo: tenant?.imageUrl || '',
              },
            })

            const tenantId = tenantResult.data?.createTenant[0].id

            const result = await mutateAsync({
              variables: {
                project: {
                  tenantId,
                  ...data,
                } as ProjectsInsertInput,
              },
            })

            const projectId = result.data?.insertProjectsOne?.id
            if (!projectId) {
              return
            }

            await createEnvs({
              variables: {
                environments: [
                  {
                    tenantId,
                    name: `${user?.fullName} Local`,
                    projectId,
                    type: EnvironmentTypesEnum.Local,
                  },
                  {
                    tenantId,
                    name: 'Staging',
                    projectId,
                    type: EnvironmentTypesEnum.Staging,
                  },
                  {
                    tenantId,
                    name: 'Prod',
                    projectId,
                    type: EnvironmentTypesEnum.Prod,
                  },
                ],
              },
            })

            invalidate()
            router.push(`/${tenant?.slug}`)
          } catch {
            snackbar.error('Failed to create project.')
          }
        }}
        submitLabel="Create your project and basic environments"
      >
        <FormLayout>
          <Field
            name="name"
            label="Project name"
            autoFocus
            rules={{ required: true }}
          />
          <Field
            name="description"
            label="Project description"
            rules={{ required: false }}
          />
        </FormLayout>
      </OnboardingStep>
    </>
  )
}
