import * as React from 'react'

import { z } from 'zod'

import {
  Box,
  Button,
  Spacer,
  MenuItem,
  Menu,
  MenuButton,
  MenuList,
  Portal,
  Tooltip,
  Link,
  Badge,
  Tag,
} from '@chakra-ui/react'
import {
  FiCheckCircle,
  FiCircle,
  FiUser,
  FiSliders,
  FiDatabase,
  FiCloud,
} from 'react-icons/fi'
import {
  GET_PROJECT_SPREADSHEET,
  CREATE_GOOGLE_SPREADSHEET,
  GoogleSpreadsheetOverviews,
  DELETE_GOOGLE_SPREADSHEET,
  GET_PROJECT_GOOGLE_SPREADSHEET_OVERVIEW,
} from '@api/client'
import {
  EmptyState,
  OverflowMenu,
  useHotkeysShortcut,
  useSnackbar,
  useLocalStorage,
  Field,
  FormLayout,
} from '@saas-ui/react'
import {
  Command,
  Toolbar,
  ToolbarButton,
  DataGridCell,
  MenuProperty,
  ToggleButtonGroup,
  ToggleButton,
  useColumns,
  getDataGridFilter,
  Filter,
  FilterType,
  FiltersAddButton,
  FilterItem,
  FilterItems,
} from '@saas-ui-pro/react'

import { ListPage, InlineSearch, useModals } from '@ui/lib'

import { useRouter } from '@app/nextjs'

import { usePath } from '@app/features/core/hooks/use-path'
import { googleDocsSpreadsheetUrl } from '@app/features/core/lib/url-helpers'
import { RelativeTime } from '@app/i18n'
import { useCurrentUser } from '@app/features/core/hooks/use-current-user'
import { useMutation, useQuery } from '@apollo/client'

const ActionCell: DataGridCell<GoogleSpreadsheetOverviews> = (cell) => {
  const snackbar = useSnackbar()

  const [deleteMutation] = useMutation(DELETE_GOOGLE_SPREADSHEET, {
    onCompleted: (data) => {
      snackbar.success({
        description: `Deleted Google Spreadsheet`,
      })
    },
    onError: () => {
      snackbar.error({
        description: `Failed to delete ${cell.row.getValue('spreadsheetName')}`,
      })
    },
  })

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <OverflowMenu size="xs">
        <MenuItem
          onClick={() =>
            deleteMutation({
              variables: { googleSpreadsheetId: cell.row.id.toString() },
            })
          }
        >
          Delete
        </MenuItem>
      </OverflowMenu>
    </Box>
  )
}

const schema = z.object({
  spreadsheetName: z
    .string()
    .min(2, 'Too short')
    .max(100, 'Too long')
    .describe('Spreadsheet name'),
  environmentId: z.string().describe('Environment'),
  trackedMdeId: z.string().describe('Master Data Entity'),
  trackedTenantId: z.string().describe('Tenant'),
  sheetName: z.string().describe('Sheet name'),
})

const AddGoogleSheetForm = (form: any) => {
  const { project } = useCurrentUser()
  const trackedMdeOptions = React.useMemo(
    () =>
      project?.trackedMdes?.map((mde) => ({
        label: mde.name,
        value: mde.id,
      })) || [],
    [project?.trackedMdes],
  )
  const environmentOptions = React.useMemo(
    () =>
      project?.environments?.map((e) => ({
        label: e.name,
        value: e.id,
      })) || [],
    [project?.environments],
  )

  const trackedTenantOptions = React.useMemo(
    () =>
      project?.trackedTenants?.map((t) => ({
        label: t.name,
        value: t.id,
      })) || [],
    [project?.trackedTenants],
  )

  return (
    <FormLayout>
      <Field
        name="trackedMdeId"
        label="Master Data Entity"
        type="select"
        options={trackedMdeOptions}
      />
      <Field
        name="environmentId"
        label="Environment"
        type="select"
        options={environmentOptions}
      />
      <Field
        name="trackedTenantId"
        label="Tenant"
        type="select"
        options={trackedTenantOptions}
      />
      <Field
        name="spreadsheetName"
        label="Spreadsheet name"
        type="text"
        //placeholder="This will be pre-computed by the above choices"
      />
      <Field
        name="sheetName"
        label="Sheet Name"
        type="text"
        // placeholder="This will be pre-computed by the above choices"
      />
    </FormLayout>
  )
}

export function GoogleSheetsPage() {
  const snackbar = useSnackbar()
  const modals = useModals()
  const router = useRouter()
  const basePath = usePath('/')
  const { project, projectId } = useCurrentUser()

  const [searchQuery, setSearchQuery] = React.useState('')

  const { data, loading: isLoading } = useQuery(
    GET_PROJECT_GOOGLE_SPREADSHEET_OVERVIEW,
    {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      variables: { projectId: projectId! },
      skip: !projectId,
    },
  )

  const [mutation, { loading: isPending }] = useMutation(
    CREATE_GOOGLE_SPREADSHEET,
    {
      onError: async (error: any, variables) => {
        if (error.response?.errors[0]?.extensions?.type === 'reauth') {
          snackbar.error({
            description:
              'You will need to re-authenticate with Google to create a Google Sheet, redirecting in 3 seconds...',
            duration: 3000,
          })
          await new Promise((resolve) => setTimeout(resolve, 3000))
          window.location.href = `/api/google/auth?project_id=${projectId}&redirect_to=${window.location.href}`
        }
      },
    },
  )

  const addGoogleSheetFromRow = React.useMemo(() => {
    return (row: GoogleSpreadsheetOverviews) => {
      if (projectId) {
        const spreadsheetName = `${project?.name} - ${row.trackedTenant.name} -  ${row.environment.name} - ${row.trackedMde.name}`
        snackbar.promise(
          mutation({
            variables: {
              projectId: projectId.toString(),
              trackedMdeId: row.trackedMde.id.toString(),
              environmentId: row.environment.id.toString(),
              trackedTenantId: row.trackedTenant.id.toString(),
              spreadsheetName,
              sheetName: row.trackedMde.name,
            },
          }),
          {
            success: `Google Sheet created: ${spreadsheetName} 🎉`,
            loading: `Creating Sheet. This will take a minute...`,
            error: `Failed to create Google Sheet`,
          },
        )
      } else {
        snackbar.error(
          `No project selected: ${projectId}, for row: '` + JSON.stringify(row),
        )
      }
    }
  }, [mutation, project?.name, projectId, snackbar])

  const spreadsheetLinkCell = React.useMemo(() => {
    return (cell: any) => {
      const spreadsheetId = cell.getValue()
      const row = cell.row.original
      return spreadsheetId ? (
        <Button
          as={Link}
          variant="secondary"
          isExternal
          href={googleDocsSpreadsheetUrl(spreadsheetId)}
          onClick={(e) => e.stopPropagation()}
        >
          Google Sheet
        </Button>
      ) : (
        <Button
          variant="primary"
          isLoading={isPending}
          onClick={(e) => {
            addGoogleSheetFromRow(row)
            e.stopPropagation()
          }}
        >
          Create Sheet
        </Button>
      )
    }
  }, [addGoogleSheetFromRow, isPending])

  const columns = useColumns<GoogleSpreadsheetOverviews>(
    (helper) => [
      {
        id: 'trackedTenant.name',
        accessorFn: (row: GoogleSpreadsheetOverviews) => row.trackedTenant.name,

        header: 'Tenant',
        size: 100,
        filterFn: getDataGridFilter(),
      },
      {
        id: 'environment.name',
        accessorFn: (row: GoogleSpreadsheetOverviews) => row.environment.name,
        header: 'Environment',
        size: 100,
        filterFn: getDataGridFilter(),
      },
      {
        id: 'trackedMde.name',
        accessorFn: (row: GoogleSpreadsheetOverviews) => row.trackedMde.name,
        header: 'Master Data Entity',
        size: 100,
        filterFn: getDataGridFilter(),
      },
      {
        id: 'status',
        size: 70,
        header: 'Status',
        accessorFn: (row: GoogleSpreadsheetOverviews) => {
          const exists = !!row.googleSpreadsheet?.spreadsheetId
          return exists ? 'active' : 'proposed'
        },
        cell: (cell: any) => {
          return (
            <Tag
              colorScheme={cell.getValue() === 'active' ? 'green' : 'purple'}
              size="sm"
              variant="outline"
            >
              {cell.getValue() === 'active' ? 'Active' : 'Proposed'}
            </Tag>
          )
        },
        filterFn: getDataGridFilter(),
      },
      // @ts-ignore
      helper.accessor('googleSpreadsheet.spreadsheetId', {
        header: 'Spreadsheet',
        size: 100,
        cell: (cell: any) => spreadsheetLinkCell(cell),
      }),
      {
        id: 'lastSyncedAt',
        accessorFn: (row: GoogleSpreadsheetOverviews) => {
          const lastSyncedAt =
            row.googleSpreadsheet?.googleSheets[0]?.lastSyncedAt
          return lastSyncedAt ? new Date(lastSyncedAt) : null
        },
        header: 'Last Synced At',
        size: 100,
        cell: (cell: any) =>
          cell.getValue() ? (
            <RelativeTime date={cell.getValue()} style="long" />
          ) : (
            'never'
          ),
      },
      helper.display({
        id: 'action',
        header: '',
        cell: ActionCell,
        size: 100,
        enableGlobalFilter: false,
        enableHiding: false,
        enableSorting: false,
      }),
    ],
    [isPending],
  )

  const addGoogleSheets = () => {
    return modals.form({
      title: 'Add Google Sheet',
      schema,
      children: AddGoogleSheetForm,
      fields: {
        submit: {
          children: 'Create',
        },
      },
      onSubmit: async (params) => {
        if (projectId) {
          await mutation({
            variables: {
              projectId,
              ...params,
            },
          })
          modals.closeAll()
        }
      },
    })
  }

  const addCommand = useHotkeysShortcut('googleSheets.add', addGoogleSheets)

  const [visibleColumns, setVisibleColumns] = useLocalStorage(
    'app.google-sheets.columns',
    [
      'status',
      'trackedMde.name',
      'environment.name',
      'trackedTenant.name',
      'googleSpreadsheet.spreadsheetId',
    ],
  )

  const displayProperties = (
    <ToggleButtonGroup
      type="checkbox"
      isAttached={false}
      size="xs"
      spacing="0"
      flexWrap="wrap"
      value={visibleColumns}
      onChange={setVisibleColumns}
    >
      {columns.map((col) => {
        if ('accessorKey' in col && col.enableHiding !== false) {
          const id = col.id || col.accessorKey
          return (
            <ToggleButton
              key={id}
              value={id}
              mb="1"
              me="1"
              color="muted"
              _checked={{ color: 'app-text', bg: 'whiteAlpha.200' }}
            >
              {col?.header?.toString() ||
                id.charAt(0).toUpperCase() + id?.toString()?.slice(1)}
            </ToggleButton>
          )
        }
        return null
      })}
    </ToggleButtonGroup>
  )

  const primaryAction = (
    <ToolbarButton
      label="Add Google Sheet"
      variant="solid"
      size="md"
      colorScheme="primary"
      onClick={addGoogleSheets}
      tooltipProps={{
        label: (
          <>
            Add a Google Sheet <Command>{addCommand}</Command>
          </>
        ),
      }}
    />
  )

  const toolbarItems = (
    <>
      <FiltersAddButton />
      <Spacer />
      <Menu>
        <MenuButton
          as={ToolbarButton}
          leftIcon={<FiSliders />}
          label="Display"
          size="sm"
          variant="tertiary"
        />
        <Portal>
          <MenuList maxW="360px">
            <MenuProperty
              label="Display properties"
              value={displayProperties}
              orientation="vertical"
            />
          </MenuList>
        </Portal>
      </Menu>
    </>
  )

  const toolbar = (
    <Toolbar size="sm">
      <InlineSearch
        placeholder="Search by name..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        onReset={() => setSearchQuery('')}
      />
    </Toolbar>
  )

  const tabbar = <Toolbar>{toolbarItems}</Toolbar>

  const emptyState = (
    <EmptyState
      title={`No Google Sheets added yet for ${project?.name}`}
      description="Add a Google Sheet to get started."
      colorScheme="primary"
      icon={FiCheckCircle}
      actions={
        <Tooltip
          label={'Try setting up Google for the project first!'}
          isDisabled={!!project?.googleDriveFolderId}
        >
          <Button
            colorScheme="primary"
            variant="solid"
            onClick={() => addGoogleSheets()}
            isDisabled={project?.googleDriveFolderId == undefined}
          >
            Add a Google Sheet
          </Button>
        </Tooltip>
      }
    />
  )

  const filters = React.useMemo((): FilterItem[] => {
    const distinctItemize = (strings: string[] | undefined): FilterItems =>
      [...new Set(strings)].map((item) => ({ id: item, label: item }))

    return [
      {
        id: 'status',
        label: 'Status',
        icon: <FiCircle />,
        type: 'enum' as FilterType,
        items: [
          {
            id: 'active',
            label: 'Active',
            icon: (
              <Badge
                boxSize="8px"
                mx="2px"
                borderRadius="full"
                bg="green.400"
              />
            ),
          },
          {
            id: 'proposed',
            label: 'Proposed',
            icon: (
              <Badge
                boxSize="8px"
                mx="2px"
                borderRadius="full"
                bg="purple.400"
              />
            ),
          },
        ],
      },
      {
        id: 'trackedTenant.name',
        label: 'Tenant',
        icon: <FiUser />,
        type: 'enum' as FilterType,
        items: distinctItemize(
          data?.googleSpreadsheetOverview?.map(
            (item) => item.trackedTenant.name,
          ),
        ),
      },
      {
        id: 'environment.name',
        label: 'Environment',
        icon: <FiCloud />,
        type: 'enum',
        items: distinctItemize(
          data?.googleSpreadsheetOverview?.map((item) => item.environment.name),
        ),
      },
      {
        id: 'trackedMde.name',
        label: 'Master Data Entity',
        icon: <FiDatabase />,
        type: 'enum',
        items: distinctItemize(
          data?.googleSpreadsheetOverview?.map((item) => item.trackedMde.name),
        ),
      },
    ]
  }, [data?.googleSpreadsheetOverview])

  const defaultFilters: Filter[] = React.useMemo(
    () => [], //[{ id: 'status', operator: 'is', value: 'active' }],
    [],
  )

  return (
    <ListPage<GoogleSpreadsheetOverviews>
      title={`Google Sheets - ${project?.name}`}
      toolbar={toolbar}
      tabbar={tabbar}
      filters={filters}
      defaultFilters={defaultFilters}
      searchQuery={searchQuery}
      emptyState={emptyState}
      onRowClick={(row) => {
        const id = row.original.googleSpreadsheet?.id
        if (id) router.push(`${basePath}/google-sheets/${id}`)
      }}
      columns={columns}
      visibleColumns={visibleColumns}
      data={data?.googleSpreadsheetOverview as GoogleSpreadsheetOverviews[]}
      isLoading={isLoading}
    />
  )
}
