import { RelativeTime } from '@app/i18n'
import { Box, Card, CardBody, Center, Flex, Icon, Text } from '@chakra-ui/react'
import { AsideBody, AsideHeader } from '@saas-ui-pro/react'
import React from 'react'
import { FiGlobe } from 'react-icons/fi'
import { Node, NodeProps, Handle, Position } from 'reactflow'
import { I18nJsonViewer } from '../i18n-json-viewer'

export type I18nNodeData = {
  label: string
  datasourceI18nId: string
  value: object
  valueHash: string
  lastSyncedAt: string
}
export type I18nNode = Node<I18nNodeData>

export function I18nNode({
  data,
  isConnectable,
  selected,
  sourcePosition = Position.Bottom,
}: NodeProps<I18nNodeData>) {
  const lastSyncedAtDate = React.useMemo(() => {
    return new Date(data.lastSyncedAt)
  }, [data.lastSyncedAt])

  return (
    <Card border={selected ? `1px dashed #999` : undefined}>
      <CardBody>
        <Center>
          <Icon boxSize={4} mr={2} as={FiGlobe}></Icon>
          <Text>Translations</Text>
        </Center>
        <Text fontSize={'sm'}>
          Synced: <RelativeTime date={lastSyncedAtDate} style="long" />
        </Text>
      </CardBody>
      <Handle
        type="source"
        position={sourcePosition}
        isConnectable={isConnectable}
      />
    </Card>
  )
}

export function I18nSidebar({ node }: { node: I18nNode }) {
  return (
    <>
      <AsideHeader>Translations</AsideHeader>
      <AsideBody>
        <I18nJsonViewer data={node.data} />
      </AsideBody>
    </>
  )
}
