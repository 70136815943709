import {
  Filter,
  getDataGridFilter,
  useColumns,
  FilterItem,
  FiltersAddButton,
  MenuProperty,
  ToggleButtonGroup,
  Toolbar,
  ToolbarButton,
  ToggleButton,
  FilterItems,
  Page,
  PageBody,
} from '@saas-ui-pro/react'
import {
  Datasource,
  useCurrentUser,
} from '@app/features/core/hooks/use-current-user'
import {
  GET_TRACKED_MDES,
  GetTrackedMdEsQuery,
  INFER_CANDIDATE_MDES,
  MdeCandidate,
} from '@api/client'
import { InlineSearch, ListPage } from '@ui/lib'
import {
  ColumnDef,
  DataTable,
  EmptyStateBody,
  EmptyStateContainer,
  EmptyStateDescription,
  EmptyStateIcon,
  EmptyStateTitle,
  Link,
  useLocalStorage,
  useSnackbar,
} from '@saas-ui/react'
import { FiShare2, FiSliders } from 'react-icons/fi'
import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  Portal,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react'
import { RelativeTime } from '@app/i18n'
import React from 'react'
import { useRouter } from '@app/nextjs'
import { usePath } from '@app/features/core/hooks/use-path'
import { useQuery } from '@apollo/client'
import {
  EnvironmentDatasourceSelect,
  EnvironmentSelect,
  MinimalEnvironment,
} from '@app/features/tenants/components/datasources/environment-select'
import { useMutation } from '@apollo/client'

type MDE = NonNullable<GetTrackedMdEsQuery>['trackedMdes'][0]

const MDECandidateTable = ({ candidates }: { candidates: MdeCandidate[] }) => {
  return (
    <DataTable
      data={candidates}
      columns={[
        {
          id: 'schema',
          header: 'Schema',
          size: 100,
        },
        {
          id: 'tableName',
          header: 'Table Name',
          size: 100,
        },
        {
          id: 'tableClusterId',
          accessorFn: (row) => row.tableClusterId.substring(0, 3) + '...',
          header: 'Cluster ID',
          size: 100,
        },
        {
          id: 'depth',
          accessorFn: (row) => row.depth || '0',
          header: 'Depth',
          size: 100,
        },
        {
          id: 'naturalKeyConstraintAst',
          header: 'Natural Key columns',
          // TODO: show columns that identify the record
          accessorFn: (row) =>
            JSON.parse(row.naturalKeyConstraintAst)['column-names']?.join(', '),
          size: 100,
        },
        {
          id: 'dependencies',
          header: 'Dependencies',
          accessorFn: (row) => row.dependencies.join(', '),
          size: 100,
        },
        {
          id: 'neededBy',
          header: 'Needed By',
          accessorFn: (row) => row.neededBy.join(', '),
          size: 100,
        },
      ]}
      isSelectable
      isSortable
    />
  )
}

const InferMDEs = () => {
  const router = useRouter()
  const basePath = usePath('/')
  const snackbar = useSnackbar()
  const { project } = useCurrentUser()

  const environments = React.useMemo(
    () =>
      project?.environments?.filter((e) => (e.datasources?.length ?? 0) > 0) ||
      [],
    [project],
  )

  const [selectedEnvironment, setSelectedEnvironment] =
    React.useState<MinimalEnvironment | null>(environments[0] ?? null)

  const [selectedDatasource, setSelectedDatasource] =
    React.useState<Datasource | null>(
      selectedEnvironment?.datasources[0] ?? null,
    )

  const [inferCandidateMDEs, { data: inferredMDEs }] =
    useMutation(INFER_CANDIDATE_MDES)

  const candidates = inferredMDEs?.inferMDECandidates?.candidates ?? []
  const hasCandidates = candidates.length > 0

  if (!project) return null

  return (
    <EmptyStateContainer colorScheme="primary">
      <EmptyStateIcon as={FiShare2} />
      <EmptyStateTitle>Setup Master Data Entities</EmptyStateTitle>
      <EmptyStateDescription mt={10} maxW={'full'}>
        <VStack>
          {!hasCandidates && (
            <>
              <Text>
                <abbr title="Master Data Entity">MDE</abbr>s ({' '}
                <Link
                  href="https://en.wikipedia.org/wiki/Master_data"
                  isExternal
                  textDecoration={'underline'}
                >
                  Master Data
                </Link>{' '}
                Entities) are groups of related tables identified by a natural
                key (unique names, external IDs, etc.).
              </Text>
              <Text>
                They enable unified data migrations instead of copying tables
                individually.
              </Text>

              <Text mt={10}>
                Select a Postgres environment and a datasource so Schemamap.io
                can infer them from your schema.
              </Text>
            </>
          )}

          <HStack>
            <EnvironmentSelect
              name="environment"
              environments={environments}
              selectedEnvironment={selectedEnvironment}
              onSelectedEnvironmentChange={(newEnv) => {
                setSelectedEnvironment(newEnv)
                setSelectedDatasource(
                  newEnv?.datasources.length == 1
                    ? newEnv.datasources[0]
                    : null,
                )
              }}
            />

            <EnvironmentDatasourceSelect
              name="datasource"
              isDisabled={!selectedEnvironment}
              datasources={selectedEnvironment?.datasources ?? []}
              selectedDatasource={selectedDatasource}
              onSelectedDatasourceChange={setSelectedDatasource}
            />
            <Button
              variant="primary"
              isDisabled={!selectedDatasource}
              onClick={() => {
                if (!selectedDatasource) return
                snackbar.promise(
                  inferCandidateMDEs({
                    variables: { datasourceIds: [selectedDatasource.id] },
                  }),
                  {
                    loading: 'Inferring MDEs...',
                    success: 'MDEs inferred successfully',
                    error: (e) => 'Failed to infer MDEs: ' + e.message,
                  },
                )
              }}
            >
              Infer MDEs
            </Button>
          </HStack>
          {hasCandidates && (
            <Box maxW={'container.2xl'} overflowX={'auto'} mb={20}>
              <MDECandidateTable candidates={candidates} />
            </Box>
          )}
        </VStack>
      </EmptyStateDescription>
    </EmptyStateContainer>
  )
}

export function MDEsPage() {
  const { project, projectId } = useCurrentUser()

  const { data, loading: isLoading } = useQuery(GET_TRACKED_MDES, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { projectId: projectId! },
    skip: !projectId,
  })

  const mdes: MDE[] = React.useMemo(
    () => data?.trackedMdes || [],
    [data?.trackedMdes],
  )

  const snackbar = useSnackbar()

  const columns = useColumns<MDE>(
    (helper) =>
      [
        {
          id: 'project',
          accessorFn: (row: MDE) => row.project?.name,
          header: 'Project',
          size: 100,
          filterFn: getDataGridFilter('string'),
        },
        {
          id: 'environments',
          accessorFn: (row: MDE) =>
            row.trackedMdeEnvironments
              .map((mde) => mde.environment.name)
              .join(', '),
          header: 'Environments',
          size: 100,
          filterFn: getDataGridFilter('string'),
        },
        {
          id: 'name',
          accessorFn: (row: MDE) => row.name,
          header: 'Name',
          size: 200,
          filterFn: getDataGridFilter('string'),
        },
        {
          id: 'shortName',
          accessorFn: (row: MDE) => row.shortName,
          header: 'Short Name',
          size: 100,
          filterFn: getDataGridFilter('string'),
        },
        helper.accessor('createdAt', {
          header: 'First Seen At',
          cell: (cell: any) =>
            cell.getValue() ? (
              <RelativeTime date={cell.getValue()} style="long" />
            ) : (
              'never'
            ),
          size: 70,
          filterFn: getDataGridFilter('datetime'),
        }),
        {
          id: 'lastSeenAt',
          header: 'Last Synced At',
          size: 80,
          accessorFn: (row: MDE) =>
            row.trackedMdeEnvironments.reduce(
              (acc, tte) => (acc > tte.lastSyncedAt ? acc : tte.lastSyncedAt),
              '',
            ),
          cell: (cell: any) =>
            cell.getValue() ? (
              <RelativeTime date={cell.getValue()} style="long" />
            ) : (
              'never'
            ),
          filterFn: getDataGridFilter('datetime'),
        },
      ] as ColumnDef<MDE>[],
    // deps
    [],
  )

  const [visibleColumns, setVisibleColumns] = useLocalStorage(
    `app.projects.mdes.visible-columns`,
    columns.map((c) => c.id).filter(Boolean) as string[],
  )

  const displayProperties = (
    <ToggleButtonGroup
      type="checkbox"
      isAttached={false}
      size="xs"
      spacing="0"
      flexWrap="wrap"
      value={visibleColumns}
      onChange={setVisibleColumns}
    >
      {columns.map((col) => {
        if ('accessorKey' in col && col.enableHiding !== false) {
          const id = col.id || col.accessorKey
          return (
            <ToggleButton
              key={id}
              value={id}
              mb="1"
              me="1"
              color="muted"
              _checked={{ color: 'app-text', bg: 'whiteAlpha.200' }}
            >
              {col?.header?.toString() ||
                id.charAt(0).toUpperCase() + id?.toString()?.slice(1)}
            </ToggleButton>
          )
        }
        return null
      })}
    </ToggleButtonGroup>
  )

  const [showInferMde, setShowInferMde] = React.useState(false)

  const toolbarItems = (
    <>
      <FiltersAddButton />
      <Spacer />
      <Menu>
        <MenuButton
          as={ToolbarButton}
          leftIcon={<FiSliders />}
          label="Display"
          size="sm"
          variant="tertiary"
        />
        <Portal>
          <MenuList maxW="360px">
            <MenuProperty
              label="Display properties"
              value={displayProperties}
              orientation="vertical"
            />
          </MenuList>
        </Portal>
      </Menu>
    </>
  )
  const tabbar = <Toolbar>{toolbarItems}</Toolbar>

  const [searchQuery, setSearchQuery] = React.useState('')

  const toolbar = (
    <Toolbar size="sm">
      <InlineSearch
        placeholder="Search by name..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        onReset={() => setSearchQuery('')}
      />
      <ToolbarButton
        label="Infer MDEs"
        variant="solid"
        size="md"
        colorScheme="primary"
        onClick={() => setShowInferMde(true)}
      />
    </Toolbar>
  )

  const filters = React.useMemo<FilterItem[]>(() => {
    const distinctItemize = (
      strings: (string | undefined)[] | undefined,
    ): FilterItems =>
      [...new Set(strings?.filter(Boolean) as string[])].map((item) => ({
        id: item,
        label: item,
      }))

    return [
      {
        id: 'project',
        label: 'Project',
        items: distinctItemize(mdes?.map((mde) => mde.project?.name)),
      },
      {
        id: 'environment',
        label: 'Environment',
        items: distinctItemize(
          mdes?.flatMap((mde) =>
            mde.trackedMdeEnvironments.map((tme) => tme.environment.name),
          ),
        ),
      },
    ]
  }, [mdes])

  const defaultFilters: Filter[] = []

  return (
    <ListPage<MDE>
      title={`Master Data Entities - ${project?.name}`}
      filters={filters}
      defaultFilters={defaultFilters}
      tabbar={tabbar}
      toolbar={toolbar}
      searchQuery={searchQuery}
      emptyState={<InferMDEs />}
      columns={columns}
      visibleColumns={visibleColumns}
      data={showInferMde ? [] : mdes}
      isLoading={isLoading}
    />
  )
}
