import { create } from 'zustand'
import {
  Connection,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  addEdge,
  OnNodesChange,
  OnEdgesChange,
  OnConnect,
  applyNodeChanges,
  applyEdgeChanges,
  ReactFlowInstance,
} from 'reactflow'

export type SwapColumArgs = {
  dirIndex: number
  id: string
  data: Node['data']
}

export type RFState = {
  nodes: Node[]
  edges: Edge[]
}

export type RFActions = {
  onNodesChange: OnNodesChange
  onEdgesChange: OnEdgesChange
  onConnect: OnConnect
  updateNode: (id: string, updateFn: (oldNode: Node) => Node) => void
  mergeNodeData: (id: string, newData: Record<string, any>) => void
  setNodes: ReactFlowInstance['setNodes']
  setEdges: ReactFlowInstance['setEdges']
  swapColumns: (swapColumnArgs: SwapColumArgs) => void
  setColumnIdentity: (id: string, identity: boolean, data: Node['data']) => void
  reset: (state?: RFState) => void
}

const initialState: RFState = {
  nodes: [],
  edges: [],
}

// this is our useStore hook that we can use in our components to get parts of the store and call actions
const useStore = create<RFState & RFActions>((set, get) => ({
  ...initialState,
  onNodesChange: (changes: NodeChange[]) => {
    set({
      nodes: applyNodeChanges(changes, get().nodes),
    })
  },
  onEdgesChange: (changes: EdgeChange[]) => {
    set({
      edges: applyEdgeChanges(changes, get().edges),
    })
  },
  onConnect: (connection: Connection) => {
    set({
      edges: addEdge(connection, get().edges),
    })
  },
  updateNode: (id: string, updateFn: (oldNode: Node) => Node) => {
    set({
      nodes: get().nodes.map((o) => {
        if (id === o.id) {
          return updateFn(o)
        }
        return o
      }),
    })
  },
  mergeNodeData: (id: string, newData: Record<string, any>) => {
    set({
      nodes: get().nodes.map((o) => {
        if (id === o.id) {
          return {
            ...o,
            data: {
              ...o.data,
              ...newData,
            },
          }
        }
        return o
      }),
    })
  },
  setNodes: (payload: Node[] | ((nodes: Node[]) => Node[])): void => {
    set((state) => ({
      nodes: typeof payload === 'function' ? payload(state.nodes) : payload,
    }))
  },
  setEdges: (payload: Edge[] | ((edges: Edge[]) => Edge[])): void => {
    set((state) => ({
      edges: typeof payload === 'function' ? payload(state.edges) : payload,
    }))
  },
  swapColumns: ({ dirIndex, id, data }: SwapColumArgs) => {
    get().setNodes((oldNodes) => {
      let nodeToMoveIdx: number | undefined
      let targetNodeIdx: number | undefined
      for (const [index, n] of oldNodes.entries()) {
        if (
          n.type === 'column' &&
          n.data.sheetColIdx === data.sheetColIdx + dirIndex
        ) {
          targetNodeIdx = index
        } else if (n.id === id) {
          nodeToMoveIdx = index
        }
      }

      if (nodeToMoveIdx === undefined || targetNodeIdx === undefined)
        return oldNodes

      const newNodes = [...oldNodes]

      // Swap positions
      const tmp = newNodes[nodeToMoveIdx]

      newNodes[nodeToMoveIdx] = {
        ...newNodes[nodeToMoveIdx],
        data: {
          ...newNodes[nodeToMoveIdx].data,
          sheetColIdx: newNodes[targetNodeIdx].data.sheetColIdx,
        },
        position: { ...newNodes[targetNodeIdx].position },
      }
      newNodes[targetNodeIdx] = {
        ...newNodes[targetNodeIdx],
        data: {
          ...newNodes[targetNodeIdx].data,
          sheetColIdx: tmp.data.sheetColIdx,
        },
        position: { ...tmp.position },
      }

      return newNodes
    })
  },
  setColumnIdentity: (id: string, identity: boolean, data: any) => {
    const oldState = get()
    const lastIdentityColumn = oldState.nodes.find(
      (n) => n.data.identity === true,
    )
    const dirIndexOffset = (lastIdentityColumn?.data?.sheetColIdx ?? -1) + 1
    oldState.swapColumns({
      dirIndex: data.sheetColIdx + dirIndexOffset,
      id,
      data,
    })
    oldState.mergeNodeData(id, { identity })
  },
  reset: (state?: RFState) => {
    // NOTE: sorting nodes so the parent nodes come first
    const newState = state ?? initialState
    const sortedNodes = newState.nodes.sort((a, b) => {
      if (a.type === 'parent') return -1
      if (b.type === 'parent') return 1
      return 0
    })
    set({ nodes: sortedNodes, edges: newState.edges })
  },
}))

export default useStore
