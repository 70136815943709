import {
  Box,
  Card,
  CardBody,
  Heading,
  Icon,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { Handle, Node, NodeProps, Position } from 'reactflow'
import { DatasourceSMO } from '../types'
import { FiUser } from 'react-icons/fi'
import { AsideHeader, AsideBody } from '@saas-ui-pro/react'
import { I18nJsonViewer } from '../i18n-json-viewer'

export type PostgresColumnNodeData = DatasourceSMO & {
  distinctValues: string[]
  listTenantsColumnAlias:
    | 'tenant_id'
    | 'tenant_short_name'
    | 'tenant_display_name'
    | string
}
export type PostgresColumnNodeType = Node<PostgresColumnNodeData>

export const postgresBlue = '#336791'

export function PostgresColumnNode({
  data,
  isConnectable,
  selected,
}: NodeProps<PostgresColumnNodeData>) {
  return (
    <Card
      border={selected ? `1px dashed` : `1px solid`}
      borderColor={postgresBlue}
    >
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
      />
      <CardBody>
        <Heading size="sm">
          {data.listTenantsColumnAlias ? (
            <Tooltip label={data.listTenantsColumnAlias} as={'span'}>
              <Icon as={FiUser} boxSize="3" mr={2} />
            </Tooltip>
          ) : null}
          {data.columnName}
        </Heading>
        <Text as="p" fontSize={'xs'}>
          {data.dataType}
        </Text>
        <Text as="p" fontSize={'xs'}>
          {data.notNull ? 'NOT NULL' : 'NULL'}
        </Text>
        <Text as="p" fontSize={'sm'}>
          {data.defaultValue ? `DEFAULT ${data.defaultValue}` : ''}
        </Text>
      </CardBody>
      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={isConnectable}
      />
    </Card>
  )
}

export function PostgresColumnSidebar({
  node,
}: {
  node: PostgresColumnNodeType
}) {
  const { data } = node
  return (
    <>
      <AsideHeader>
        {data.schemaName}.{data.tableName} / {data.columnName}
      </AsideHeader>
      <AsideBody>
        <I18nJsonViewer data={data} />
      </AsideBody>
    </>
  )
}
