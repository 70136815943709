import { Box, Card, CardBody, Heading, Icon } from '@chakra-ui/react'
import { Node, NodeProps, Handle, Position } from 'reactflow'
import { SiGooglesheets } from 'react-icons/si'
import { I18nJsonViewer } from '../i18n-json-viewer'
import { AsideBody, AsideHeader } from '@saas-ui-pro/react'

export type GoogleSpreadsheetNodeData = {
  label: string
  mdeViewSql?: string
}
export type GoogleSpreadsheetNodeType = Node<GoogleSpreadsheetNodeData>

export const googleSheetGreen = '#0F9D58'

export function GoogleSpreadsheetNode({
  data,
  selected,
}: NodeProps<GoogleSpreadsheetNodeData>) {
  return (
    <Card
      width={'full'}
      height={'full'}
      borderColor={googleSheetGreen}
      border={selected ? `1px dashed ${googleSheetGreen}` : undefined}
    >
      <CardBody>
        <Heading size="sm" display="flex" alignItems="center">
          <Icon
            color={googleSheetGreen}
            as={SiGooglesheets}
            boxSize="6"
            mr="2"
          />
          {data.label}
        </Heading>
      </CardBody>
    </Card>
  )
}

export function GoogleSpreadsheetSidebar({
  node,
}: {
  node: GoogleSpreadsheetNodeType
}) {
  const { data } = node
  return (
    <>
      <AsideHeader>{data.label}</AsideHeader>
      <AsideBody>
        <I18nJsonViewer data={data} />
      </AsideBody>
    </>
  )
}
