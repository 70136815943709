import { NodeTypes, Node } from 'reactflow'
import { ColumnNodeData, ColumnSidebar, ColumnNode } from './column'
import { I18nNodeData, I18nSidebar, I18nNode } from './i18n'
import {
  GoogleSpreadsheetNodeType,
  GoogleSpreadsheetNodeData,
  googleSheetGreen,
  GoogleSpreadsheetNode,
  GoogleSpreadsheetSidebar,
} from './google-spreadsheet'
import {
  PostgresTableNodeData,
  PostgresTableSidebar,
  PostgresTableNode,
  PostgresTableNodeType,
} from './postgres-table'
import {
  PostgresColumnNodeData,
  PostgresColumnSidebar,
  PostgresColumnNodeType,
  PostgresColumnNode,
} from './postgres-column'
import { I18nJsonViewer } from '../i18n-json-viewer'

export * from './column'
export * from './i18n'
export * from './google-spreadsheet'
export * from './postgres-table'
export * from './postgres-column'

export const nodeTypes: NodeTypes = {
  column: ColumnNode,
  i18n: I18nNode,
  googleSpreadsheet: GoogleSpreadsheetNode,
  postgresTable: PostgresTableNode,
  postgresColumn: PostgresColumnNode,
} as const

export type GoogleSpreadsheetReactFlowNodeData =
  | ColumnNodeData
  | I18nNodeData
  | GoogleSpreadsheetNodeData
  | PostgresTableNodeData
  | PostgresColumnNodeData

export const minimapNodeColor = (node: Node): string => {
  switch (node.type as keyof typeof nodeTypes) {
    case 'column':
      return '#333'
    case 'rowCell':
      return '#333'
    case 'sheetCell':
      return '#333'
    case 'i18n':
      return '#111'
    case 'googleSpreadsheet':
      return googleSheetGreen
    case 'postgresTable':
      return '#333'
    case 'postgresColumn':
      return '#333'
    default:
      return '#333'
  }
}

export const nodeHumanName = (node: Node): string | undefined => {
  switch (node.type as keyof typeof nodeTypes) {
    case 'column':
      return 'Spreadsheet Column'
    case 'rowCell':
      return 'Row Cell'
    case 'sheetCell':
      return 'Sheet Cell'
    case 'i18n':
      return 'I18n'
    case 'googleSpreadsheet':
      return 'Google Spreadsheet'
    case 'postgresTable':
      return 'Postgres Table'
    case 'postgresColumn':
      return 'Postgres Column'
    default:
      return node.type
  }
}

export const NodeSidebar = ({ node }: { node: Node }) => {
  switch (node.type as keyof typeof nodeTypes) {
    case 'googleSpreadsheet':
      return (
        <GoogleSpreadsheetSidebar node={node as GoogleSpreadsheetNodeType} />
      )
    case 'column':
      return <ColumnSidebar node={node as ColumnNode} />
    case 'i18n':
      return <I18nSidebar node={node} />
    case 'postgresTable':
      return <PostgresTableSidebar node={node as PostgresTableNodeType} />
    case 'postgresColumn':
      return <PostgresColumnSidebar node={node as PostgresColumnNodeType} />
    default:
      return <I18nJsonViewer data={node.data} />
  }
}
