import * as React from 'react'

import { useRouter } from '@app/nextjs'
import { useWorkspace } from '@app/features/core/hooks/use-workspace'
import { useCurrentUser } from '@app/features/core/hooks/use-current-user'
import { useAuth } from '@saas-ui/auth'
import { AppLoader } from '@ui/lib'

export const HomePage: React.FC = () => {
  const router = useRouter()
  const { tenant } = useCurrentUser()
  const workspace = useWorkspace()

  const { isAuthenticated, isLoading } = useAuth()

  React.useEffect(() => {
    if (workspace) {
      router.push(`/${workspace}`)
    } else if (!isLoading && !isAuthenticated) {
      router.push('/login')
    }
  }, [workspace, router, isAuthenticated, isLoading])

  return <AppLoader />
}
