import * as React from 'react'

import {
  LoadingOverlay,
  LoadingSpinner,
  StepperProvider,
  Steps,
  StepsCompleted,
  StepsItem,
} from '@saas-ui/react'
import {
  OnboardingPage,
  CreateOrganizationStep,
} from '../../components/onboarding'
import { useSteps, Button, Divider, VStack } from '@chakra-ui/react'
import { useRouter } from '@app/nextjs'
import { SkipSection } from './shared'
import { useOrganization } from '@clerk/clerk-react'

export const CreateOrganizationPage: React.FC = () => {
  const router = useRouter()
  const { organization } = useOrganization()
  return (
    <OnboardingPage>
      <Steps
        step={0}
        maxW={'container.xl'}
        variant={['dots', null, null, 'horizontal']}
      >
        <StepsItem title="Create organization" id="create-organization">
          <CreateOrganizationStep></CreateOrganizationStep>
          {organization?.slug && (
            <SkipSection
              goToNext={() => {
                router.push('/getting-started/create-project')
              }}
            />
          )}
        </StepsItem>
        <StepsItem title="Create project" id="create-project" />
      </Steps>
    </OnboardingPage>
  )
}

export default CreateOrganizationPage
