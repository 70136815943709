import {
  Box,
  Card,
  CardBody,
  Center,
  Checkbox,
  Code,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import {
  Node,
  NodeProps,
  Handle,
  Position,
  useReactFlow,
  ReactFlowInstance,
} from 'reactflow'
import { DatasourceSMO } from '../types'
import {
  FiArrowDown,
  FiArrowLeft,
  FiArrowRight,
  FiArrowUp,
  FiDatabase,
  FiEye,
  FiEyeOff,
  FiGlobe,
  FiKey,
} from 'react-icons/fi'
import React, { useState } from 'react'
import { I18nJsonViewer } from '../i18n-json-viewer'
import {
  PropertyList,
  Property,
  SelectButton,
  SelectList,
  Select,
} from '@saas-ui/react'
import useStore from '../store'
import { useDebouncedCallback } from '@react-hookz/web'
import ContrastColor from 'contrast-color'
import { set } from 'zod'
import { AsideBody, AsideHeader } from '@saas-ui-pro/react'
import Aside from '@saas-ui-pro/react/src/theme/components/aside'
// FIXME: Error: Package subpath './react/shallow' is not defined by "exports" in /Users/kszabo/projects/schemamap-pro/frontend/node_modules/zustand/package.json
// import { useShallow } from 'zustand/react/shallow'

// HACK: Use typescript string literals to define the type of the color with lowercase hexa with # prefix
type RGB = string
const cc = new ContrastColor()

export type GoogleSheetConditionType = 'ONE_OF_LIST' | string

// Mirroring Google Sheet validation rule
export type DataValidationRule = {
  range: {
    sheetId: number
    startRowIndex: number
    endRowIndex?: number
    startColumnIndex: number
    endColumnIndex: number
  }
  rule: {
    condition: {
      type: GoogleSheetConditionType
      values?: { userEnteredValue: string }[]
    }
    inputMessage: string
    showCustomUi: boolean
  }
}

type ValueMapping = { [dbValue: string]: string }

export type ColumnNodeData = {
  label: string
  sampleValue: any
  colIdx: number
  sheetColIdx: number
  sheetLabel?: string
  hidden?: boolean
  identity?: boolean
  nonSynced?: boolean
  smo?: DatasourceSMO
  backgroundColor?: RGB
  foregroundColor?: RGB
  comment?: string
  bidiMappingFns: string[]
  googleSheetType: GoogleSheetCellDataType
  dataValidationRules?: DataValidationRule[]
  columnName: string
  defaultValue?: string
  schemaName: string
  valueMapping?: ValueMapping
}
export type ColumnNode = Node<ColumnNodeData>

type GoogleSheetCellDataType =
  | 'number'
  | 'text'
  | 'boolean'
  | 'date'
  | 'time'
  | 'one_of_list'
  | string

const dataTypes: { label: string; value: GoogleSheetCellDataType }[] = [
  { label: 'Number', value: 'number' },
  { label: 'Text', value: 'text' },
  { label: 'Boolean', value: 'boolean' },
  { label: 'Date', value: 'date' },
  { label: 'Time', value: 'time' },
  { label: 'One of List', value: 'one_of_list' },
  /* { label: 'Currency', value: 'Currency' },
  { label: 'Percentage', value: 'Percentage' },
  { label: 'Error', value: 'Error' },
  { label: 'OneOfList', value: 'OneOfList' },
  { label: 'Array', value: 'Array' },
  { label: 'Hyperlink', value: 'Hyperlink' },
  { label: 'Duration', value: 'Duration' },*/
]

const identity = (x: string) => x
const simulatedBidiFns = {
  trim_str: { forwardFn: (x: string) => x.trim(), backwardFn: identity },
  identity: { forwardFn: identity, backwardFn: identity },
  value_map: { forwardFn: identity, backwardFn: identity },
} as const

export const ColumnNode = ({
  id,
  data,
  selected,
  isConnectable,
  targetPosition = Position.Top,
  sourcePosition = Position.Bottom,
}: NodeProps<ColumnNodeData>) => {
  const reactFlow = useReactFlow()
  const isFirst = data.sheetColIdx === 0
  const { swapColumns, nodes } = useStore(
    // FIXME: Error: Package subpath './react/shallow' is not defined by "exports" in /Users/kszabo/projects/schemamap-pro/frontend/node_modules/zustand/package.json
    (state) => ({
      swapColumns: state.swapColumns,
      nodes: state.nodes,
    }),
    //)
  )
  const googleSheetColumnCount = React.useMemo(
    () => nodes.filter((n) => n.type === 'column').length,
    [nodes],
  )
  const isLast = data.sheetColIdx === googleSheetColumnCount - 1

  const handleMoveColumn = React.useCallback(
    (dirIndex: number) => swapColumns({ dirIndex, id, data }),
    [swapColumns, id, data],
  )
  const [value, setValue] = useState(data.sampleValue ?? (undefined as any))
  const [sheetValue, setSheetValue] = useState(
    data.sampleValue ?? (undefined as any),
  )

  // simulate a two-way binding between sample value and sheet value, turn it into a callback that gets the new values
  const changeValues = React.useMemo(() => {
    const bidiMappingFns = data.bidiMappingFns.map((fnName: string) => {
      const fn = simulatedBidiFns[fnName as keyof typeof simulatedBidiFns]
      return fn
    })

    return (
      newDbValue: string | undefined,
      newSheetValue: string | undefined,
    ) => {
      if (newDbValue === undefined && newSheetValue === undefined) {
        setValue(undefined)
        setSheetValue(undefined)
      }
      if (newSheetValue !== undefined) {
        setValue(
          bidiMappingFns.reduce(
            (acc, { forwardFn }) => forwardFn(acc),
            newSheetValue,
          ),
        )
        setSheetValue(newSheetValue)
      }
      if (newDbValue !== undefined) {
        setSheetValue(
          bidiMappingFns.reduce(
            (acc, { backwardFn }) => backwardFn(acc),
            newDbValue,
          ),
        )
        setValue(newDbValue)
      }
    }
  }, [data.bidiMappingFns])

  return (
    <Card
      flexDir={'row'}
      alignItems={'center'}
      backgroundColor={data.backgroundColor}
      textColor={data.foregroundColor}
      opacity={data.hidden || data.nonSynced ? 0.4 : 1}
      border={selected ? '1px dashed #999' : undefined}
    >
      <Handle
        type="target"
        position={targetPosition}
        isConnectable={isConnectable}
      />
      <Tooltip label="Move column left">
        <IconButton
          icon={<FiArrowLeft />}
          className="nodrag"
          mx={1}
          minW={'1'}
          height={'5rem'}
          isDisabled={isFirst}
          variant={'ghost'}
          onClick={() => handleMoveColumn(-1)}
          aria-label="Move column left"
        />
      </Tooltip>
      <CardBody
        px={0}
        textOverflow={'ellipsis'}
        overflow={'hidden'}
        whiteSpace={'nowrap'}
        width={'full'}
      >
        <VStack>
          <Center
            width={'full'}
            borderBottom="1px"
            borderColor={'gray.200'}
            pb={1}
            mb={2}
          >
            {data.sheetLabel ? (
              data.sheetLabel
            ) : (
              <Center overflow={'hidden'}>
                <Tooltip label="Missing translation">
                  <Center>
                    <Icon
                      as={FiGlobe}
                      boxSize="4"
                      color={'yellow.300'}
                      mr={1}
                    />
                  </Center>
                </Tooltip>

                <Code colorScheme="yellow" textOverflow={'ellipsis'}>
                  {data.label}
                </Code>
              </Center>
            )}
            {data.identity && (
              <Tooltip label="Record identity">
                <Center>
                  <Icon as={FiKey} boxSize="4" color={'gray.400'} ml={2} />
                </Center>
              </Tooltip>
            )}
          </Center>

          {data.googleSheetType === 'one_of_list' && data.valueMapping ? (
            <Box width={'90%'}>
              <Select
                name="db-value"
                value={value}
                onChange={(value) => changeValues(value as string, undefined)}
                options={
                  Object.entries(data.valueMapping).map(([dbValue, _]) => ({
                    label: dbValue,
                    value: dbValue,
                  })) ?? []
                }
              >
                <SelectButton leftIcon={<FiDatabase />} />
                <SelectList />
              </Select>
            </Box>
          ) : (
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <FiDatabase />
              </InputLeftElement>
              <Input
                value={value}
                placeholder="Database value"
                isDisabled={data.nonSynced}
                onChange={(e) => changeValues(e.target.value, undefined)}
              />
            </InputGroup>
          )}

          {data.nonSynced != true && (
            <>
              {data.bidiMappingFns.map((fnName) => (
                <HStack key={fnName}>
                  <Icon as={FiArrowDown} color={'gray.400'} />
                  <Code>{fnName}</Code>
                  <Icon as={FiArrowUp} color={'gray.400'} />
                </HStack>
              ))}

              {data.googleSheetType === 'one_of_list' && data.valueMapping ? (
                <Box width={'90%'}>
                  <Select
                    name="sheet-value"
                    value={value}
                    onChange={(value) =>
                      changeValues(undefined, value as string)
                    }
                    options={
                      Object.entries(data.valueMapping).map(
                        ([dbValue, sheetValue]) => ({
                          label: sheetValue,
                          value: dbValue,
                        }),
                      ) ?? []
                    }
                  >
                    <SelectButton leftIcon={<FiEye />} />
                    <SelectList />
                  </Select>
                </Box>
              ) : data.googleSheetType === 'boolean' ? (
                <Checkbox
                  isChecked={sheetValue === 'true'}
                  onChange={(e) =>
                    changeValues(undefined, e.target.checked ? 'true' : 'false')
                  }
                />
              ) : (
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    {data.hidden ? <FiEyeOff /> : <FiEye />}
                  </InputLeftElement>
                  <Input
                    value={sheetValue}
                    placeholder="Sheet value"
                    onChange={(e) => changeValues(undefined, e.target.value)}
                  ></Input>
                </InputGroup>
              )}
            </>
          )}
        </VStack>
      </CardBody>
      <Tooltip label="Move column right">
        <IconButton
          icon={<FiArrowRight />}
          mx={1}
          minW={'1'}
          height={'5rem'}
          className="nodrag"
          isDisabled={isLast}
          variant={'ghost'}
          aria-label="Move column right"
          onClick={() => handleMoveColumn(1)}
        />
      </Tooltip>
      <Handle
        type="source"
        position={sourcePosition}
        isConnectable={isConnectable}
      />
    </Card>
  )
}

export function ColumnSidebar({ node }: { node: ColumnNode }) {
  const mergeNodeData = useStore((state) => state.mergeNodeData)
  const setColumnIdentity = useStore((state) => state.setColumnIdentity)
  const updateNode = useStore((s) => s.updateNode)

  const { data } = node
  const onBGColorChange = useDebouncedCallback(
    (value?: string) => {
      mergeNodeData(node.id, {
        backgroundColor: value,
        foregroundColor: cc.contrastColor({ bgColor: value }),
      })
    },
    [data.foregroundColor],
    50,
  )

  const onFGColorChange = useDebouncedCallback(
    (value?: string) => {
      mergeNodeData(node.id, {
        foregroundColor: value,
      })
    },
    [],
    50,
  )

  return (
    <>
      <AsideHeader>{data.label}</AsideHeader>
      <AsideBody>
        <I18nJsonViewer data={node.data} />
        <PropertyList mt={4}>
          <Property
            label="Do Not Sync"
            value={
              <Checkbox
                isChecked={data.nonSynced}
                onChange={(e) =>
                  mergeNodeData(node.id, { nonSynced: e.target.checked })
                }
              />
            }
          />
          <Property
            label="Default Value"
            value={
              <Input
                type="text"
                value={data.defaultValue}
                onChange={(e) => {
                  mergeNodeData(node.id, { defaultValue: e.target.value })
                }}
              />
            }
          />
          {!data.nonSynced && (
            <>
              <Property
                label="Identity"
                value={
                  <Checkbox
                    isChecked={data.identity ?? false}
                    onChange={(e) =>
                      setColumnIdentity(node.id, e.target.checked, node.data)
                    }
                  />
                }
              />
              <Property
                label="Column Header"
                value={
                  <Input
                    type="text"
                    value={data.sheetLabel}
                    onChange={(e) => {
                      mergeNodeData(node.id, { sheetLabel: e.target.value })
                    }}
                    placeholder={data.label}
                  />
                }
              />
              <Property
                label="Comment"
                value={
                  <Input
                    type="text"
                    value={data.comment}
                    onChange={(e) => {
                      mergeNodeData(node.id, { comment: e.target.value })
                    }}
                    placeholder={'Add a comment'}
                  />
                }
              />
              <Property
                label="Hidden?"
                value={
                  <Checkbox
                    checked={data.hidden}
                    onChange={(e) =>
                      mergeNodeData(node.id, { hidden: e.target.checked })
                    }
                  />
                }
              />
              <Property
                label="BG Color"
                value={
                  <Input
                    type="color"
                    value={data.backgroundColor ?? '#ffffff'}
                    onChange={(evt) => onBGColorChange(evt.target.value)}
                  />
                }
              />
              <Property
                label="FG Color"
                value={
                  <Input
                    type="color"
                    value={data.foregroundColor ?? '#000000'}
                    onChange={(evt) => onFGColorChange(evt.target.value)}
                  />
                }
              />
              <Property
                label="Data Type"
                value={
                  <Select
                    name="data-type"
                    size={'xs'}
                    autoFocus
                    value={node.data.googleSheetType ?? 'text'}
                    onChange={(value) => {
                      mergeNodeData(node.id, {
                        googleSheetType: value,
                      })
                    }}
                    options={dataTypes}
                  >
                    <SelectButton />
                    <SelectList />
                  </Select>
                }
              />
            </>
          )}
        </PropertyList>
      </AsideBody>
    </>
  )
}
