import { Box, Card, CardBody, Heading, Icon } from '@chakra-ui/react'
import { AsideHeader, AsideBody } from '@saas-ui-pro/react'
import { SiPostgresql } from 'react-icons/si'
import { Node, NodeProps } from 'reactflow'
import { I18nJsonViewer } from '../i18n-json-viewer'

export type PostgresTableNodeData = {
  schemaName: string
  tableName: string
}
export type PostgresTableNodeType = Node<PostgresTableNodeData>

const postgresBlue = '#336791'
export function PostgresTableNode({
  data,
  selected,
}: NodeProps<PostgresTableNodeData>) {
  return (
    <Card
      borderColor={postgresBlue}
      width={'full'}
      height={'200'}
      border={selected ? `1px dashed #999` : undefined}
    >
      <CardBody>
        <Heading size="sm" display="flex" alignItems="center">
          <Icon as={SiPostgresql} boxSize="6" mr="2" />
          {data.schemaName}.{data.tableName}
        </Heading>
      </CardBody>
    </Card>
  )
}

export function PostgresTableSidebar({
  node,
}: {
  node: PostgresTableNodeType
}) {
  const { data } = node
  return (
    <>
      <AsideHeader>
        {data.schemaName}.{data.tableName}
      </AsideHeader>
      <AsideBody>
        <I18nJsonViewer data={data} />
      </AsideBody>
    </>
  )
}
