import { format } from 'date-fns'

interface DateCellProps {
  date?: string
  defaultValue?: string
}

export const DateCell = ({ date, defaultValue }: DateCellProps) => {
  const dateobj = date ? new Date(date) : undefined
  const iso = dateobj?.toISOString()
  return (
    <time dateTime={iso}>{dateobj ? format(dateobj, 'PP') : defaultValue}</time>
  )
}
