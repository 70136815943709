import {
  Filter,
  getDataGridFilter,
  useColumns,
  FilterItem,
  FiltersAddButton,
  MenuProperty,
  ToggleButtonGroup,
  Toolbar,
  ToolbarButton,
  ToggleButton,
  FilterItems,
} from '@saas-ui-pro/react'
import { useCurrentUser } from '@app/features/core/hooks/use-current-user'
import { GET_TRACKED_TENANTS, GetTrackedTenantsQuery } from '@api/client'
import { InlineSearch, ListPage } from '@ui/lib'
import {
  ColumnDef,
  EmptyState,
  useLocalStorage,
  useSnackbar,
} from '@saas-ui/react'
import { FiCheckCircle, FiSliders } from 'react-icons/fi'
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  Portal,
  Spacer,
} from '@chakra-ui/react'
import { RelativeTime } from '@app/i18n'
import React from 'react'
import { useQuery } from '@apollo/client'

type TT = NonNullable<GetTrackedTenantsQuery>['trackedTenants'][0]

export function TenantsPage() {
  const { project, projectId } = useCurrentUser()

  const { data: trackedTenants, loading: isLoading } = useQuery(
    GET_TRACKED_TENANTS,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    { variables: { projectId: projectId! }, skip: !projectId },
  )

  const tts: TT[] = React.useMemo(
    () => trackedTenants?.trackedTenants || [],
    [trackedTenants?.trackedTenants],
  )

  const snackbar = useSnackbar()

  const emptyState = (
    <EmptyState
      title="No tracked tenants"
      colorScheme="primary"
      icon={FiCheckCircle}
      actions={
        <>
          <Button
            colorScheme="primary"
            variant="solid"
            onClick={() => alert('TODO')}
          >
            Track tenants for your Postgres datasources
          </Button>
        </>
      }
    />
  )

  const columns = useColumns<TT>(
    (helper) =>
      [
        {
          id: 'project',
          accessorFn: (row: TT) => row.project?.name,
          header: 'Project',
          size: 100,
          filterFn: getDataGridFilter('string'),
        },
        {
          id: 'environments',
          accessorFn: (row: TT) =>
            row.trackedTenantEnvironments
              .map((tte) => tte.environment.name)
              .join(', '),
          header: 'Environments',
          size: 100,
          filterFn: getDataGridFilter('string'),
        },
        {
          id: 'name',
          accessorFn: (row: TT) => row.name,
          header: 'Name',
          size: 200,
          filterFn: getDataGridFilter('string'),
        },
        {
          id: 'shortName',
          accessorFn: (row: TT) => row.shortName,
          header: 'Short Name',
          size: 100,
          filterFn: getDataGridFilter('string'),
        },
        helper.accessor('createdAt', {
          header: 'First Seen At',
          cell: (cell: any) =>
            cell.getValue() ? (
              <RelativeTime date={cell.getValue()} style="long" />
            ) : (
              'never'
            ),
          size: 70,
          filterFn: getDataGridFilter('datetime'),
        }),
        {
          id: 'lastSeenAt',
          header: 'Last Synced At',
          size: 80,
          accessorFn: (row: TT) =>
            row.trackedTenantEnvironments.reduce(
              (acc, tte) => (acc > tte.lastSyncedAt ? acc : tte.lastSyncedAt),
              '',
            ),
          cell: (cell: any) =>
            cell.getValue() ? (
              <RelativeTime date={cell.getValue()} style="long" />
            ) : (
              'never'
            ),
          filterFn: getDataGridFilter('datetime'),
        },
      ] as ColumnDef<TT>[],
    // deps
    [],
  )

  const [visibleColumns, setVisibleColumns] = useLocalStorage(
    `app.projects.tenants.visible-columns`,
    columns.map((c) => c.id).filter(Boolean) as string[],
  )

  const displayProperties = (
    <ToggleButtonGroup
      type="checkbox"
      isAttached={false}
      size="xs"
      spacing="0"
      flexWrap="wrap"
      value={visibleColumns}
      onChange={setVisibleColumns}
    >
      {columns.map((col) => {
        if ('accessorKey' in col && col.enableHiding !== false) {
          const id = col.id || col.accessorKey
          return (
            <ToggleButton
              key={id}
              value={id}
              mb="1"
              me="1"
              color="muted"
              _checked={{ color: 'app-text', bg: 'whiteAlpha.200' }}
            >
              {col?.header?.toString() ||
                id.charAt(0).toUpperCase() + id?.toString()?.slice(1)}
            </ToggleButton>
          )
        }
        return null
      })}
    </ToggleButtonGroup>
  )

  const toolbarItems = (
    <>
      <FiltersAddButton />
      <Spacer />
      <Menu>
        <MenuButton
          as={ToolbarButton}
          leftIcon={<FiSliders />}
          label="Display"
          size="sm"
          variant="tertiary"
        />
        <Portal>
          <MenuList maxW="360px">
            <MenuProperty
              label="Display properties"
              value={displayProperties}
              orientation="vertical"
            />
          </MenuList>
        </Portal>
      </Menu>
    </>
  )
  const tabbar = <Toolbar>{toolbarItems}</Toolbar>

  const [searchQuery, setSearchQuery] = React.useState('')

  const toolbar = (
    <Toolbar size="sm">
      <InlineSearch
        placeholder="Search by column..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        onReset={() => setSearchQuery('')}
      />
    </Toolbar>
  )

  const filters = React.useMemo<FilterItem[]>(() => {
    const distinctItemize = (
      strings: (string | undefined)[] | undefined,
    ): FilterItems =>
      [...new Set(strings?.filter(Boolean) as string[])].map((item) => ({
        id: item,
        label: item,
      }))

    return [
      {
        id: 'project',
        label: 'Project',
        items: distinctItemize(tts?.map((tt) => tt.project?.name)),
      },
      {
        id: 'environments',
        label: 'Environments',
        items: distinctItemize(
          tts?.flatMap((tt) =>
            tt.trackedTenantEnvironments.map((tte) => tte.environment.name),
          ),
        ),
      },
    ]
  }, [tts])

  const defaultFilters: Filter[] = []
  return (
    <ListPage<TT>
      title={`Tenants - ${project?.name}`}
      filters={filters}
      defaultFilters={defaultFilters}
      tabbar={tabbar}
      toolbar={toolbar}
      searchQuery={searchQuery}
      emptyState={emptyState}
      columns={columns}
      visibleColumns={visibleColumns}
      data={tts}
      isLoading={isLoading}
    />
  )
}
