import { Flex } from '@chakra-ui/react'
import { CreateOrganization } from '@clerk/clerk-react'
import { dark } from '@clerk/themes'

export const CreateOrganizationStep = () => {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      maxW={'400'}
      mt={20}
      mx={'auto'}
    >
      <CreateOrganization appearance={{ baseTheme: dark }} />
    </Flex>
  )
}
