import { Heading, HStack, Spacer, useDisclosure } from '@chakra-ui/react'
import { FiSidebar } from 'react-icons/fi'

import {
  Page,
  PageBody,
  PageHeader,
  Toolbar,
  ToolbarButton,
} from '@saas-ui-pro/react'
import { Breadcrumbs } from '@ui/lib'

import { usePath } from '@app/features/core/hooks/use-path'
import { useQuery } from '@apollo/client'
import { GET_PROJECT } from '@api/client'

interface ProjectPageProps {
  id: string
}

export function ProjectPage({ id }: ProjectPageProps) {
  const {
    data,
    loading: isLoading,
    error,
  } = useQuery(GET_PROJECT, {
    variables: { id },
  })
  const project = data?.projectsByPk

  const sidebar = useDisclosure({
    defaultIsOpen: true,
  })

  const breadcrumbs = (
    <Breadcrumbs
      items={[
        { href: usePath('/projects'), title: 'Projects' },
        { title: project?.name },
      ]}
    />
  )

  const toolbar = (
    <Toolbar>
      <Spacer />
      <ToolbarButton
        icon={<FiSidebar />}
        label={sidebar.isOpen ? 'Hide project details' : 'Show project details'}
        onClick={sidebar.onToggle}
      />
    </Toolbar>
  )

  return (
    <Page isLoading={isLoading}>
      <PageHeader title={breadcrumbs} toolbar={toolbar} />
      <PageBody contentWidth="full" p="0">
        <HStack
          alignItems="stretch"
          width="100%"
          height="100%"
          overflowX="hidden"
          position="relative"
          spacing="0"
        >
          <Heading>TODO</Heading>
        </HStack>
      </PageBody>
    </Page>
  )
}
