import * as React from 'react'

import {
  PREVIEW_POSTGRES_ENVIRONMENT_SYNC,
  PostgresEnvironmentSyncPreview,
  SYNC_POSTGRES_ENVIRONMENT,
} from '@api/client'
import { SnackbarOptions, Steps, StepsItem, useSnackbar } from '@saas-ui/react'
import { estimateSnackbarDuration } from '@app/features/core/lib/snackbar-helpers'

import {
  Datasource,
  useCurrentUser,
} from '@app/features/core/hooks/use-current-user'
import { useMutation } from '@apollo/client'
import { Box, HStack } from '@chakra-ui/react'
import {
  BackButton,
  Page,
  PageBody,
  PageHeader,
  PageTitle,
} from '@saas-ui-pro/react'
import { MinimalEnvironment } from '@app/features/tenants/components/datasources/environment-select'
import { PostgresEnvSyncConfigure, PostgresEnvSyncToolbar } from './configure'
import { PgEnvSyncSteps } from './helper'
import { PostgresEnvSyncReview, PostgresEnvSyncReviewToolbar } from './review'
import { PostgresEnvSyncResult } from './sync'

export function PostgresPage() {
  const { project } = useCurrentUser()

  const [previewEnvSync, { data, loading: isLoadingPreview }] = useMutation(
    PREVIEW_POSTGRES_ENVIRONMENT_SYNC,
  )

  const preview = data?.previewPostgresEnvironmentSync ?? null

  const [selectedMDE, setSelectedMDE] = React.useState<string | undefined>(
    undefined,
  )

  const [selectedTrackedTenant, setSelectedTrackedTenant] = React.useState<
    string | undefined
  >(undefined)

  const environments = React.useMemo(
    () => project?.environments?.filter((e) => e.datasources?.length > 0) || [],
    [project],
  )

  const [selectedSourceEnvironment, setSelectedSourceEnvironment] =
    React.useState<MinimalEnvironment | null>(null)

  const [selectedTargetEnvironment, setSelectedTargetEnvironment] =
    React.useState<MinimalEnvironment | null>(null)

  const [selectedSourceDatasource, setSelectedSourceDatasource] =
    React.useState<Datasource | null>(null)

  const [selectedTargetDatasource, setSelectedTargetDatasource] =
    React.useState<Datasource | null>(null)

  const [currentStep, setCurrentStep] =
    React.useState<PgEnvSyncSteps>('configure')

  const [sourceWhere, setSourceWhere] = React.useState('')

  const pageHeader = React.useMemo(() => {
    return (
      <HStack alignItems={'center'} justifyContent={'center'} p={0}>
        <PageTitle mr={0}>
          Postgres Environment Sync - {project?.name}
        </PageTitle>
        <Box fontSize={'xl'} mx={2}>
          /
        </Box>
        <Box>
          {currentStep !== 'configure' && (
            <BackButton
              onClick={() => {
                setCurrentStep(currentStep == 'sync' ? 'review' : 'configure')
              }}
              title="One step back"
            />
          )}
        </Box>
        <Steps step={currentStep} variant={'outline'}>
          <StepsItem
            title="Configure"
            name={'configure' satisfies PgEnvSyncSteps}
            onClick={() => {
              if (currentStep == 'sync' || currentStep == 'review')
                setCurrentStep('configure')
            }}
          />
          <StepsItem
            title="Review"
            name={'review' satisfies PgEnvSyncSteps}
            onClick={() => {
              if (currentStep == 'sync') setCurrentStep('review')
            }}
          />
          <StepsItem title="Sync" name={'sync' satisfies PgEnvSyncSteps} />
        </Steps>
      </HStack>
    )
  }, [project?.name, currentStep])

  const snackbar = useSnackbar()

  const [sync, { data: syncResult, loading: isSyncingPgEnv }] = useMutation(
    SYNC_POSTGRES_ENVIRONMENT,
    {},
  )

  const calculatePreview = React.useMemo(() => {
    return async () => {
      if (!selectedSourceDatasource || !selectedTargetDatasource) return

      await snackbar.promise(
        previewEnvSync({
          variables: {
            trackedMdeId: selectedMDE,
            sourceDatasourceId: selectedSourceDatasource.id,
            targetDatasourceId: selectedTargetDatasource.id,
            sourceTrackedTenantId: selectedTrackedTenant,
            targetTrackedTenantId: selectedTrackedTenant,
            sourceWhere,
          },
        }),
        {
          loading: 'Calculating preview of environment sync...',
          success: 'Generated preview of environment sync',
          error: (e) =>
            ({
              title: 'Failed to generate preview of environment sync',
              description: e.message,
              duration: estimateSnackbarDuration(e.message),
            }) satisfies SnackbarOptions,
        },
      )
    }
  }, [
    previewEnvSync,
    selectedMDE,
    selectedSourceDatasource,
    selectedTargetDatasource,
    selectedTrackedTenant,
    snackbar,
    sourceWhere,
  ])

  const toolbar = React.useMemo(() => {
    switch (currentStep) {
      case 'configure':
        return (
          <PostgresEnvSyncToolbar
            project={project ?? null}
            preview={preview}
            selectedMDE={selectedMDE}
            selectedTrackedTenant={selectedTrackedTenant}
            selectedSourceDatasource={selectedSourceDatasource}
            selectedTargetDatasource={selectedTargetDatasource}
            calculatePreview={calculatePreview}
            isLoadingPreview={isLoadingPreview}
            previewEnvSync={previewEnvSync}
            setSelectedMDE={setSelectedMDE}
            setCurrentStep={setCurrentStep}
          />
        )
      case 'review':
        return (
          <PostgresEnvSyncReviewToolbar
            preview={preview}
            previewEnvSync={previewEnvSync}
            setSelectedMDE={setSelectedMDE}
            setCurrentStep={setCurrentStep}
            isLoadingPreview={isLoadingPreview}
            calculatePreview={calculatePreview}
            isSyncingPgEnv={isSyncingPgEnv}
            selectedMDE={selectedMDE}
            selectedTrackedTenant={selectedTrackedTenant}
            selectedSourceDatasource={selectedSourceDatasource}
            selectedTargetDatasource={selectedTargetDatasource}
            sourceWhere={sourceWhere}
            sync={sync}
          />
        )
    }
  }, [
    calculatePreview,
    currentStep,
    isLoadingPreview,
    isSyncingPgEnv,
    preview,
    previewEnvSync,
    project,
    selectedMDE,
    selectedSourceDatasource,
    selectedTargetDatasource,
    selectedTrackedTenant,
    sourceWhere,
    sync,
  ])

  return (
    <Page
      position="relative"
      isLoading={currentStep != 'configure' && isLoadingPreview}
    >
      <PageHeader title={pageHeader} toolbar={toolbar} />
      <PageBody p="0" contentWidth="full">
        {currentStep === 'configure' ? (
          <PostgresEnvSyncConfigure
            project={project ?? null}
            environments={environments}
            preview={preview ?? null}
            selectedSourceDatasource={selectedSourceDatasource}
            setSelectedSourceDatasource={setSelectedSourceDatasource}
            selectedTargetDatasource={selectedTargetDatasource}
            setSelectedTargetDatasource={setSelectedTargetDatasource}
            selectedMDE={selectedMDE}
            setCurrentStep={setCurrentStep}
            selectedSourceEnvironment={selectedSourceEnvironment}
            setSelectedSourceEnvironment={setSelectedSourceEnvironment}
            selectedTargetEnvironment={selectedTargetEnvironment}
            setSelectedTargetEnvironment={setSelectedTargetEnvironment}
            selectedTrackedTenant={selectedTrackedTenant}
            setSelectedTrackedTenant={setSelectedTrackedTenant}
            isLoadingPreview={isLoadingPreview}
            sourceWhere={sourceWhere}
            setSourceWhere={setSourceWhere}
          />
        ) : preview && currentStep == 'review' ? (
          <PostgresEnvSyncReview
            key={1}
            preview={preview}
            sync={sync}
            sourceWhere={sourceWhere}
            isSyncingPgEnv={isSyncingPgEnv}
          />
        ) : syncResult && currentStep == 'sync' ? (
          <PostgresEnvSyncResult syncResult={syncResult} />
        ) : null}
      </PageBody>
    </Page>
  )
}
